// native
import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';

// addon
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

// service
import { OperatorsService } from 'app/core/services/operators.service';
import { DoctorsService } from 'app/core/services/doctors.service';
import { TestsService } from 'app/core/services/tests.service';
import { TestFormService } from 'app/core/services/test-form.service';
import { DevicesService } from 'app/core/services/devices.service';
import { AuthService } from 'app/core/services/auth.service';
import { PreferencesService } from 'app/core/services/preferences.service';
import { TestsRecurringService } from 'app/core/services/tests-recurring.service';
import { PatientsService } from 'app/core/services/patients.service';
import { TestBundlesService } from 'app/core/services/test-bundles.service';
import { MonitorTestService } from 'app/core/services/monitor-test.service';
import { UtilityService } from 'app/core/services/utility.service';
import { TestDefaultService } from 'app/core/services/test-default.service';
import { ErrorService } from 'app/core/services/error.service';
import { DialogService } from 'app/core/services/dialog.service';

// models
import {
  Device, Test, Patient, Operator, Doctor, CorrectionType,
  SupraTIntensity, UserPreferences, TestPostRequest, RecurringTestPostRequest, CenterContainerTitle,
  TestLanguage, TestGroup, TestProtocol, TestStrategy, ControlType, CustomBundleTestCreationRequest, PendingTestBundleItem, CustomBundleDescription, PendingCustomBundleItem
} from '../../models';

// constant
import {
  EYE, SUPRA_T_INTENSITIES, GOLDMAN_SIZES, DEVICE_TYPE, NONE_CORRECTION_TYPE, GROUP, STRATEGY, CONTROL_TYPES,
  TEST_STATUS_STARTED_ID, DEFAULT_OCCLUSION_TIME, TESTING_POSITIONS, APD_STIMULATION_TIMES, DEFAULT_APD_STIMULATION_TIME
} from '../../constants';

@Component({
  selector: 'app-test-form',
  templateUrl: './test-form.component.html'
})
export class TestFormComponent implements OnInit, OnChanges, OnDestroy {

  @Input() action: string;
  @Input() test?: Test | PendingTestBundleItem | CustomBundleDescription | PendingCustomBundleItem;
  @Input() firstTest?: boolean;
  @Input() mwlItemId?: string;
  @Input() patient?: Patient;
  @Input() isDisabled?: boolean;

  title: CenterContainerTitle = {};
  form: UntypedFormGroup;

  isNew: boolean;
  isPending: boolean;

  isLoading = false;
  isHomeDevice: boolean = false;
  isEyeTrackingDevice: boolean = false;
  isRecurringSelected: boolean = false;
  minEndDate = new Date();

  devices: Device[] = [];
  doctors: Doctor[] = [];
  operators: Operator[] = [];
  correctionTypes: CorrectionType[] = [];
  controlTypes: ControlType[] = [];
  stimulusTypes: string[] = [];
  supraTIntensities: SupraTIntensity[] = Object.keys(SUPRA_T_INTENSITIES).map(key => SUPRA_T_INTENSITIES[key]);
  testingPositions: { label: string; value: string; }[] = Object.keys(TESTING_POSITIONS).map(key => TESTING_POSITIONS[key]);
  apdStimulationTimes = APD_STIMULATION_TIMES;

  allTestGroups: TestGroup[] = [];
  displayedTestGroups: TestGroup[] = [];
  displayedTestStrategies: TestStrategy[] = [];
  displayedTestProtocols: TestProtocol[] = [];

  isCustomBundleForm: boolean = false;
  allCustomBundleGroups: TestGroup[] = [];
  displayedCustomBundleGroups: TestGroup[] = [];
  isCustomBundleWithConvergence: boolean = false;

  displayedLanguages: TestLanguage[] = [];
  preferences: UserPreferences;

  isVoiceWarningDisplayed: boolean = false;

  currentStep: number = 1;
  currentBundleStep: number = 0;

  shouldSetDefaults: boolean = false;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public devicesService: DevicesService,
    public doctorsService: DoctorsService,
    public operatorsService: OperatorsService,
    public patientsService: PatientsService,
    public testsService: TestsService,
    public testsRecurringService: TestsRecurringService,
    public toastService: ToastrService,
    public testFormService: TestFormService,
    public router: Router,
    public authService: AuthService,
    public preferencesService: PreferencesService,
    public testBundlesService: TestBundlesService,
    public translateService: TranslateService,
    public monitorTestService: MonitorTestService,
    public utilityService: UtilityService,
    public testDefaultService: TestDefaultService,
    public errorService: ErrorService,
    public dialogService: DialogService
  ) { }

  ngOnInit() {
    this.isNew = !this.test;
    this.isLoading = true;

    this.translateService.get('test').subscribe(() => this.setTranslations());

    this.getFormData().pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(
      ([testGroups, controlTypes, stimulusTypes, operators, doctors, devices, preferences, correctionTypes]) => {

        this.allTestGroups = (<TestGroup[]>testGroups).filter(group => !group.custom_test_bundle_info);
        this.displayedTestGroups = [...this.allTestGroups];
        this.controlTypes = controlTypes;
        this.stimulusTypes = stimulusTypes;
        this.operators = operators;
        this.doctors = doctors;
        this.devices = devices;
        this.preferences = preferences;
        this.correctionTypes = correctionTypes;

        this.allCustomBundleGroups = (<TestGroup[]>testGroups).filter(group => !!group.custom_test_bundle_info);

        this.initForm(this.test);
      }, error => this.errorService.handleError());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['action']?.currentValue)
      this.title.thin = changes['action']?.currentValue;
  }

  ngOnDestroy(): void {
    this.testFormService.hideGroupVisualHint();
    this.testFormService.hideStrategyVisualHint();
    this.testFormService.hideLanguageVisualHint();
  }

  public setTranslations() {
    this.title.bold = this.translateService.instant('test');
  }

  public getFormData(): Observable<any[]> {
    const patientId = this.authService.isTechallAdmin ? this.patient?.id : undefined;
    return forkJoin([
      this.testsService.getTestGroups(false, patientId),
      this.testsService.getControlTypes(),
      this.testsService.getStimulusTypes(),
      this.operatorsService.getAll(patientId),
      this.doctorsService.getAll(patientId),
      this.devicesService.getAllWithAvailableTests(patientId),
      this.preferencesService.get(),
      this.testsService.getCorrectionTypes(),
      this.testsService.getAllTestLanguages(patientId)
    ]);
  }

  initForm(test: Test | PendingTestBundleItem | CustomBundleDescription | PendingCustomBundleItem, isPending: boolean = false) {
    this.isPending = isPending;
    this.shouldSetDefaults = this.isNew && !this.isPending;

    const { device, doctor, operator, test_group, with_correction, correction_type, eye, supra_t_intensity, goldman_size, control_type, language,
      skip_convergence_testing, skip_tutorial, use_short_tutorial, monocular, disable_pause, test_foveal_sensitivity,
      apd_stimulation_time, subtitle_increase_text_size, skip_eye_tracking_calibration, custom_test_bundle_type, stimulus_type,
      skip_calibration, subtitles, occlusion_time, cover_directions, cover_digitally_occluded } = <Test | PendingTestBundleItem>test || ({} as Test);

    const convergenceSkipped = this.getConvergenceSkipped(skip_convergence_testing);
    const initialLanguage = this.getInitialLanguage(language);

    this.form = this.formBuilder.group({
      device: [device ? this.devices.find(dev => dev.id === device) : this.getDeviceFromPreferences(), [Validators.required]],
      doctor: [this.getDoctor(doctor), [Validators.required]],
      operator: [this.getOperator(operator), !this.authService.isTechallAdmin ? [Validators.required] : null],
      group: [this.getGroup(test_group?.group), [Validators.required]],
      strategy: [test_group?.strategy?.value, [Validators.required]],
      protocol: [test_group?.protocol?.value, [Validators.required]],
      with_correction: [with_correction || false],
      correction_type: [correction_type || NONE_CORRECTION_TYPE],
      eye: [eye || EYE.BOTH_CLIENT],
      goldman_size: [this.shouldSetDefaults ? GOLDMAN_SIZES.THREE.value : goldman_size],
      supra_t_intensity: [supra_t_intensity || SUPRA_T_INTENSITIES.STANDARD.value],
      control_type: [control_type],
      language: [initialLanguage],
      disable_pause: [this.shouldSetDefaults ? this.preferences.disable_pause : disable_pause],
      skip_convergence_testing: [convergenceSkipped],
      skip_tutorial: [this.shouldSetDefaults ? this.preferences.skip_tutorial : skip_tutorial],
      use_short_tutorial: [this.shouldSetDefaults ? this.preferences.use_short_tutorial : use_short_tutorial],
      monocular: [this.getMonocular(monocular), convergenceSkipped ? [Validators.required] : null],
      test_foveal_sensitivity: [this.shouldSetDefaults ? false : test_foveal_sensitivity],
      custom_test_bundle_type: [this.shouldSetDefaults ? this.preferences.custom_test_bundle_type : custom_test_bundle_type, [Validators.required]],
      stimulus_type: [stimulus_type],
      apd_stimulation_time: [apd_stimulation_time || DEFAULT_APD_STIMULATION_TIME],
      skip_calibration: [skip_calibration],
      skip_eye_tracking_calibration: [skip_eye_tracking_calibration !== undefined ? skip_eye_tracking_calibration : false],
      subtitles: [this.getSubtitles(subtitles)],
      subtitle_increase_text_size: [subtitle_increase_text_size || false],
      occlusion_time: [occlusion_time || DEFAULT_OCCLUSION_TIME],
      patient_email: (<Test>this.test)?.patient?.email,
      patient_phone: (<Test>this.test)?.patient?.phone,
      period_in_days: null,
      end_date: null,
      monocular_confrontation: [null],
      monocular_color: [null],
      cover_directions: [this.shouldSetDefaults ? TESTING_POSITIONS.PRIMARY_ONLY.value : cover_directions],
      cover_digitally_occluded: [cover_digitally_occluded !== undefined ? cover_digitally_occluded : true]
    });

    this.setDeviceChangeListener();
    this.setGroupChangeListener();
    this.setStrategyChangeListener();
    this.setProtocolChangeListener();

    this.form.get('patient_phone').valueChanges.subscribe(() => this.onPatientPhoneChange());

    if (this.shouldSetDefaults)
      this.onDeviceChange(this.getDeviceFromPreferences());

    // forces triggering strategy and/or protocol logic for initial test group selection
    this.onTestGroupChange(true);

    this.isDisabled && this.form.disable();
  }

  private getGroup(group: Partial<TestGroup>) {
    if (!this.shouldSetDefaults)
      return group?.value;

    if (!!this.preferences.use_custom_test_bundle_type)
      return this.testFormService.customBundleGroupValue;

    return this.preferences.test_group?.group?.value;
  }

  private getDeviceFromPreferences() {
    const device = this.devices.find(dev => dev.id === this.preferences?.device?.id);
    if (!device)
      return null;
    if (device.administering_test || !device.available)
      return null;
    return device;
  }

  private getInitialLanguage(existingLanguage: string): string {
    return this.shouldSetDefaults ? (this.preferences.language || this.testFormService.getDisplayedLanguages()[0]?.value) : existingLanguage;
  }

  private getSubtitles(existingSubtitles: boolean): boolean {
    if (this.mwlItemId)
      return !!this.preferences.always_use_subtitles;

    return this.shouldSetDefaults ? this.patient?.subtitles : existingSubtitles;
  }

  private getConvergenceSkipped(skipConvergenceExisting: boolean): boolean {
    if (this.isPending)
      return !!skipConvergenceExisting;

    if (this.isNew && this.patient.monocular !== null)
      return true;

    if (this.isNew)
      return this.preferences.skip_convergence_testing;

    return !!skipConvergenceExisting;
  }

  private getMonocular(monocularExisting: boolean): boolean {
    if (this.isPending)
      return !!monocularExisting;

    if (this.isNew && this.patient.monocular !== null)
      return this.patient.monocular;

    if (this.isNew)
      return this.preferences.skip_convergence_testing ? true : null;

    return !!monocularExisting;
  }

  private getDoctor(doctor: Partial<Doctor>): number {
    if (doctor)
      return doctor.id;
    return this.authService.doctorId || this.patient?.doctor_id;
  }

  private getOperator(operator: Partial<Operator>): number {
    if (operator)
      return operator.id;
    return this.preferences.operator?.id || this.authService.operatorId;
  }

  protected setGroupChangeListener() {
    this.form.get('group').valueChanges.subscribe(() => {
      this.onTestGroupChange();
    });
  }

  protected setStrategyChangeListener() {
    this.form.get('strategy').valueChanges.subscribe(() => {
      this.onTestStrategyChange();
    });
  }

  protected setProtocolChangeListener() {
    this.form.get('protocol').valueChanges.subscribe(() => {
      this.onTestProtocolChange();
    });
  }

  private setDeviceChangeListener() {
    this.form.get('device').valueChanges.subscribe((device: Device) => {
      this.onDeviceChange(device);
    });
  }

  protected onTestGroupChange(isInitialSet: boolean = false) {
    this.setStrategyDisplay(isInitialSet);
    this.setProtocolDisplay(isInitialSet);
    this.testFormService.setCustomBundleDisplay(this.form, isInitialSet);
    this.setLanguageDisplay();

    if (this.isNew) {
      const shouldSetOdEye = this.testFormService.isEyeMovableTest(this.selectedTestGroup) ||
        this.testFormService.isSingleEyeTest(this.selectedTestGroup, this.selectedTestStrategy, this.selectedDevice, this.isCustomBundleForm) && this.selectedMonocular;

      this.form.controls['eye'].setValue(shouldSetOdEye ? EYE.OD : EYE.BOTH_CLIENT);
    }
  }

  protected setStrategyDisplay(isInitialSet: boolean = false) {
    this.displayedTestStrategies = this.selectedTestGroup ? this.selectedTestGroup.strategies?.slice(0) : [];

    if (!this.displayedTestStrategies?.length) {
      this.form.controls['strategy'].disable();
      this.form.controls['strategy'].setValue(null);
    } else if (this.displayedTestStrategies.length === 1) {
      this.form.controls['strategy'].enable();
      this.form.controls['strategy'].setValue(this.displayedTestStrategies[0].value);
    } else {
      this.isNew && this.form.controls['strategy'].enable();

      this.isNew && this.form.controls['strategy'].setValue(
        isInitialSet
          ? this.isPending
            ? this.test.test_group?.strategy?.value
            : this.preferences.test_group?.strategy?.value
          : null);
    }
  }

  protected setProtocolDisplay(isInitialSet: boolean = false) {
    const protocols = this.selectedTestGroup?.protocols?.length ? this.selectedTestGroup.protocols : this.selectedTestStrategy?.protocols;
    this.displayedTestProtocols = protocols || [];

    if (!this.displayedTestProtocols?.length) {
      this.form.controls['protocol'].disable();
      this.form.controls['protocol'].setValue(null);
    } else if (this.displayedTestProtocols.length === 1) {
      this.form.controls['protocol'].enable();
      this.form.controls['protocol'].setValue(this.displayedTestProtocols[0].value);
    } else {
      this.isNew && this.form.controls['protocol'].enable();

      this.isNew && this.form.controls['protocol'].setValue(
        isInitialSet
          ? this.isPending
            ? this.test.test_group?.protocol?.value
            : this.preferences.test_group?.protocol?.value
          : null);
    }
  }

  protected setDefaultGoldmanSizeDisplay() {
    if (this.testFormService.isGoldmanSizeConfigurable(this.selectedTestStrategy, this.selectedDevice)) {
      const defaultSize = this.testFormService.getDefaultGoldmanSize(this.selectedTestStrategy, this.selectedTestProtocol);
      this.form.controls['goldman_size'].setValue(defaultSize);
    }
  }

  protected setLanguageDisplay() {
    const hasControlType = this.testFormService.isTestWithControlType(this.selectedTestGroup, this.selectedTestStrategy, this.selectedTestProtocol);
    this.displayedLanguages = this.testFormService.getDisplayedLanguages(this.selectedDevice, this.selectedTestGroup, this.selectedTestStrategy,
      hasControlType ? this.selectedControlType : null);
    this.checkSelectedLanguageValidity();
  }

  private onTestStrategyChange() {
    this.setProtocolDisplay();
    this.setLanguageDisplay();
    this.isNew && this.setDefaultGoldmanSizeDisplay();

    if (this.selectedTestStrategy?.value === STRATEGY.CONTRAST_STANDARD) {
      this.form.controls['monocular']?.setValue(!!this.preferences?.use_monocular_for_contrast_sensitivity);
      this.form.controls['monocular']?.setValidators(null);
    }
    else if (!this.testFormService.isTestWithConvergence(this.selectedTestStrategy))
      this.form.controls['monocular']?.setValidators(null);
    else if (this.form.controls['skip_convergence_testing']?.value)
      this.form.controls['monocular']?.setValidators(Validators.required);

    if (this.testFormService.isTestWithOcclusionTime(this.selectedTestGroup, this.selectedTestStrategy))
      this.form.controls['occlusion_time']?.enable();
    else
      this.form.controls['occlusion_time']?.disable();

    this.form.controls['monocular']?.updateValueAndValidity();

    if (this.selectedTestStrategy?.value === STRATEGY.ESTERMAN)
      this.form.controls['eye']?.setValue(EYE.BOTH_CLIENT);
  }

  private onTestProtocolChange() {
    this.setLanguageDisplay();
    this.isNew && this.setDefaultGoldmanSizeDisplay();
  }

  protected onDeviceChange(device: Device) {
    this.isHomeDevice = [DEVICE_TYPE.HOME, DEVICE_TYPE.HOME_EYE_TRACKING].includes(device?.device_type);
    this.isEyeTrackingDevice = (device?.device_type === DEVICE_TYPE.EYE_TRACKING);

    const hadGroupSelected = !!this.selectedTestGroup;
    const hadStrategySelected = !!this.selectedTestStrategy;

    this.displayedTestGroups = device?.capabilities?.supported_test_groups ? device.capabilities.supported_test_groups.filter(group => !group.custom_test_bundle_info) : this.allTestGroups;
    this.displayedTestStrategies = this.selectedTestGroup?.strategies;

    this.displayedTestProtocols = this.selectedTestGroup?.protocols?.length ? this.selectedTestGroup.protocols : this.selectedTestStrategy?.protocols;
    if (this.displayedTestProtocols?.length === 1)
      this.form.controls['protocol'].setValue(this.displayedTestProtocols[0].value);

    this.setLanguageDisplay();

    if (!this.currentBundleStep)
      this.displayedCustomBundleGroups = device?.capabilities?.supported_test_groups ? device.capabilities.supported_test_groups.filter(group => !!group.custom_test_bundle_info) : this.allCustomBundleGroups;
    if (this.testFormService.areCustomBundlesDisplayed) {
      this.testFormService.handleCustomBundleDisplayOnDeviceChange(this.form, this.displayedCustomBundleGroups);
      this.onCustomBundleChange(this.form.controls['custom_test_bundle_type']?.value);
      return;
    }

    this.testFormService.resetSelectionAndShowHint(this.form, this.selectedTestGroup, this.selectedTestStrategy, hadGroupSelected, hadStrategySelected);
  }

  protected onLanguageChange(language: string) { }

  cancelCreate() {
    const controlTypeControllerId = this.controlTypes.find(type => type.name === CONTROL_TYPES.CONTROLLER)?.id;
    this.form.controls['control_type']?.setValue(controlTypeControllerId);
    this.form.controls['control_type']?.updateValueAndValidity();
    this.onControlTypeChange(controlTypeControllerId);
  }

  onSubmit(form: UntypedFormGroup, createRecurring = false) {
    if (!form.valid)
      return;

    // todo check if next 2 lines can be removed
    this.form.controls.skip_convergence_testing.enable();
    this.form.controls.monocular.enable();

    const status = TEST_STATUS_STARTED_ID;
    const patient = this.patient.id || null;
    const device: Device = this.form.controls['device']?.value;

    if (this.form.value.custom_test_bundle_type) {
      const body = this.getCustomBundleBody(patient, this.mwlItemId);
      this.submitSystemOrCustomBundle(body, createRecurring, device, true);
      return;
    }

    const body = this.getRegularTestBody(status, patient, this.mwlItemId);

    // todo: this mapping will be removed after next round of api changes
    if (this.selectedTestGroup?.value === GROUP.CSUITE)
      return this.submitSystemOrCustomBundle(body, createRecurring, device);

    this.isLoading = true;
    this.testsService.create(body).subscribe(test => {
      if (!createRecurring) {
        this.isLoading = false;
        return this.monitorTestService.openMonitorScreen(test);
      }

      const recurringBody: RecurringTestPostRequest = {
        patient: test.patient.id,
        period_in_days: this.form.value.period_in_days,
        end_date: this.utilityService.convertClientDateToServerDate(this.form.value.end_date),
        test: test.id
      };

      this.testsRecurringService.create(recurringBody).pipe(
        finalize(() => this.isLoading = false)
      ).subscribe(res => {
        this.monitorTestService.openMonitorScreen(test);
      }, error => this.errorService.handleTestError(error));
    }, error => {
      this.isLoading = false;
      this.errorService.handleTestError(error);
    });
  }

  getCustomBundleBody(patient: number, mwlItemId: string): CustomBundleTestCreationRequest {
    const { device, doctor, operator, language, monocular, skip_convergence_testing, skip_tutorial,
      use_short_tutorial, disable_pause, subtitles, custom_test_bundle_type, subtitle_increase_text_size } = this.form.value;

    const body: CustomBundleTestCreationRequest = {
      patient,
      device: device.id,
      doctor,
      operator,
      custom_test_bundle_type,
      custom_test_bundle_parameters: {
        monocular: (skip_convergence_testing && this.isCustomBundleWithConvergence) ? monocular : null,
        skip_tutorial: this.testFormService.isTestWithTutorial(this.selectedTestGroup, this.selectedTestStrategy, this.selectedTestProtocol) ? skip_tutorial : true,
        disable_pause: this.testFormService.isTestWithDisablePause(this.selectedDevice, this.selectedTestStrategy) ? disable_pause : true,
        skip_convergence_testing: this.isCustomBundleWithConvergence ? skip_convergence_testing : true,
        use_short_tutorial,
        language,
        subtitles: this.testFormService.isTestWithSubtitles(this.selectedDevice, this.selectedLanguage, this.selectedTestGroup)
          ? this.preferences.always_use_subtitles ? true : subtitles
          : false,
        subtitle_increase_text_size: this.testFormService.isTestWithIncreasedSubtitles(this.selectedDevice, this.selectedLanguage, this.selectedSubtitles, this.selectedTestGroup, this.selectedTestStrategy)
          ? subtitle_increase_text_size
          : false,
      },
      test_descriptions: []
    };

    if (mwlItemId)
      body.mwl_query_result_item = mwlItemId;

    return body;
  }

  getRegularTestBody(status: number, patient: number, mwlItemId: string): TestPostRequest {
    const { device, doctor, operator, group, strategy, protocol, with_correction, correction_type, eye, supra_t_intensity, goldman_size, control_type, language,
      monocular, skip_convergence_testing, skip_tutorial, use_short_tutorial, disable_pause, test_foveal_sensitivity, stimulus_type, apd_stimulation_time,
      skip_calibration, skip_eye_tracking_calibration, subtitle_increase_text_size, subtitles, occlusion_time, cover_directions, cover_digitally_occluded } = this.form.value;

    const body: TestPostRequest = {
      patient,
      status,
      device: device.id,
      doctor,
      operator,
      test_group: {
        group,
        strategy,
        protocol
      },
      with_correction,
      correction_type: this.correctionTypes?.map(type => type.id).includes(correction_type) ? correction_type : null,
      eye: this.testFormService.calculateEyePayload(this.selectedTestGroup, this.selectedTestStrategy, this.selectedDevice, this.selectedMonocular, eye),
      supra_t_intensity: this.testFormService.calculateIntensityPayload(this.selectedTestStrategy, this.selectedTestProtocol, this.selectedDevice, supra_t_intensity),
      goldman_size: this.testFormService.calculateGoldmanSizePayload(this.selectedTestStrategy, goldman_size),
      control_type: this.calculateControlType(control_type, this.selectedTestGroup, this.selectedTestStrategy, this.selectedTestProtocol),
      language,
      monocular: this.testFormService.calculateMonocularPayload(skip_convergence_testing, monocular, this.selectedTestGroup, this.selectedTestStrategy, this.selectedEye, this.selectedDevice),
      skip_convergence_testing: this.testFormService.isTestWithConvergence(this.selectedTestStrategy) ? skip_convergence_testing : true,
      skip_tutorial: this.testFormService.isTestWithTutorial(this.selectedTestGroup, this.selectedTestStrategy, this.selectedTestProtocol) ? skip_tutorial : true,
      use_short_tutorial: this.testFormService.isMainLanguage(language) ? use_short_tutorial : true,
      disable_pause: this.testFormService.isTestWithDisablePause(this.selectedDevice, this.selectedTestStrategy) ? disable_pause : true,
      test_foveal_sensitivity: this.testFormService.isTestWithFoveal(this.selectedTestStrategy, this.selectedDevice) ? test_foveal_sensitivity : false,
      stimulus_type: this.testFormService.isTestWithStimulusType(this.selectedTestStrategy) ? stimulus_type : null,
      apd_stimulation_time: this.testFormService.isTestWithApdStimulationTime(this.selectedTestStrategy) ? apd_stimulation_time : null,
      skip_calibration: this.testFormService
        .isTestWithSkipCalibration(this.selectedTestGroup, this.selectedTestProtocol, this.selectedControlType) ? !!skip_calibration : false,
      skip_eye_tracking_calibration: this.testFormService
        .isTestWithSkipEyeTrackingCalibration(this.selectedTestGroup, this.selectedTestStrategy) ? !!skip_eye_tracking_calibration : null,
      subtitles: this.testFormService
        .isTestWithSubtitles(this.selectedDevice, this.selectedLanguage, this.selectedTestGroup, this.selectedTestStrategy)
        ? this.preferences.always_use_subtitles ? true : subtitles
        : false,
      occlusion_time: this.testFormService.calculateOcclusionTimePayload(this.selectedTestGroup, this.selectedTestStrategy, occlusion_time),
      cover_directions: this.testFormService.isTestWithPositions(this.selectedTestStrategy) ? cover_directions : null,
      cover_digitally_occluded: this.testFormService.isTestWithDefaultDigitalOcclusion(this.selectedTestStrategy) ? cover_digitally_occluded : false,
      subtitle_increase_text_size: this.testFormService.isTestWithIncreasedSubtitles(this.selectedDevice, this.selectedLanguage, this.selectedSubtitles, this.selectedTestGroup, this.selectedTestStrategy)
        ? subtitle_increase_text_size
        : false,
    };

    if (mwlItemId)
      body.mwl_query_result_item = mwlItemId;

    return body;
  }

  submitSystemOrCustomBundle(body: TestPostRequest | CustomBundleTestCreationRequest, createRecurring: boolean, device: Device, isCustomBundle: boolean = false) {
    let bundleBody;
    if (this.selectedTestGroup?.value === GROUP.CSUITE) {
      const deviceVersion = this.devicesService.getSemanticVersion(device?.app_version);
      bundleBody = this.testBundlesService.getBundleBodyForCSuite(<TestPostRequest>body, deviceVersion, this.controlTypes, this.form);
    }
    else if (isCustomBundle)
      bundleBody = body;
    else
      return;

    this.isLoading = true;
    this.testBundlesService.create(bundleBody).subscribe(bundle => {
      if (!createRecurring) {
        this.isLoading = false;
        return this.monitorTestService.openMonitorScreen(bundle.current_test);
      }

      const recurringBody: RecurringTestPostRequest = {
        patient: this.patient.id || null,
        period_in_days: this.form.value.period_in_days,
        end_date: this.utilityService.convertClientDateToServerDate(this.form.value.end_date),
        test_bundle: bundle.id
      };

      this.testsRecurringService.create(recurringBody).pipe(
        finalize(() => this.isLoading = false)
      ).subscribe(res => {
        this.monitorTestService.openMonitorScreen(bundle.current_test);
      }, error => this.errorService.handleTestError(error));
    }, error => {
      this.isLoading = false;
      this.errorService.handleTestError(error);
    });
  }

  protected calculateControlType(selectedType: number, group: Partial<TestGroup>, strategy: Partial<TestStrategy>, protocol: Partial<TestProtocol>, isCustomBundle: boolean = false): number {
    if (!this.testFormService.isTestWithControlType(group, strategy, protocol))
      return null;
    if (selectedType)
      return selectedType;

    const voiceSupportLanguages = strategy?.voice_input_languages || group?.voice_input_languages || [];
    const voiceSupportedLanguageSelected = voiceSupportLanguages.includes(this.selectedLanguage) || isCustomBundle;

    if ((group?.value === GROUP.CSUITE || strategy?.value === STRATEGY.ISHIHARA) && voiceSupportedLanguageSelected)
      return this.controlTypes.find(type => type.name === CONTROL_TYPES.VOICE)?.id;
    return this.controlTypes.find(type => type.name === CONTROL_TYPES.CONTROLLER)?.id;
  }

  private checkSelectedLanguageValidity() {
    const currentLanguageValid = !!this.displayedLanguages.find(lang => lang.value === this.form.controls.language?.value);
    if (!currentLanguageValid) {
      const newLanguage = this.testFormService.getDisplayedLanguages()[0]?.value;
      this.form.controls.language?.setValue(newLanguage);
      this.testFormService.showLanguageVisualHint();
    }
  }

  resume() {
    if (!this.test)
      return;

    this.testsService.changeStartedState((<Test>this.test).id).subscribe(test => this.monitorTestService.openMonitorScreen(test),
      error => this.errorService.handleError(this.translateService.instant('resumeErrorMessageGeneric'))
    );
  }

  viewReport() {
    if (!this.test)
      return;

    if ((<Test>this.test).is_external)
      return this.testsService.downloadPDFReport(<Test>this.test).pipe(
        finalize(() => this.isLoading = false)
      ).subscribe(() => { }, () => this.errorService.handleError(this.translateService.instant('reportErrorMessageGeneric')));

    this.isLoading = true;
    const windowRef = window.open('', '_blank');

    this.patientsService.getOne((<Test>this.test).patient.id).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(patient => {
      const bundleId = (<Test>this.test).test_bundle?.id;

      if (!bundleId)
        return this.testsService.openHTMLReport((<Test>this.test).id, patient.age, windowRef);

      this.isLoading = true;
      this.testBundlesService.getBundleReportMode(bundleId).pipe(
        finalize(() => (this.isLoading = false))
      ).subscribe(mode => {
        if (mode.singlepage)
          this.testBundlesService.openHTMLReportWithDialog(bundleId, patient.age, windowRef);
        else
          this.testBundlesService.openHTMLReport(bundleId, 'full', patient.age, windowRef);
      }, err => this.testsService.openHTMLReport((<Test>this.test).id, patient.age, windowRef));
    }, err => windowRef.close());
  }

  getGroupByValue(value: string): TestGroup {
    return this.displayedTestGroups.find(group => group.value === value);
  }

  getStrategyByValue(value: string): TestStrategy {
    return this.selectedTestGroup?.strategies?.find(strategy => strategy.value === value);
  }

  getProtocolByValue(value: string): TestProtocol {
    if (this.selectedTestStrategy)
      return this.selectedTestStrategy?.protocols?.find(protocol => protocol.value === value);

    return this.selectedTestGroup?.protocols?.find(protocol => protocol.value === value);
  }

  get selectedTestGroup(): TestGroup {
    const selectedGroupValue = this.form.controls['group'].value;

    if (selectedGroupValue === GROUP.CUSTOM_BUNDLE) {
      return {
        name: this.translateService.instant('customBundle'),
        value: GROUP.CUSTOM_BUNDLE
      };
    }

    const selectedGroup = this.getGroupByValue(selectedGroupValue);
    return selectedGroup;
  }

  get selectedTestStrategy(): TestStrategy {
    const selectedStrategyValue = this.form.controls['strategy'].value;
    const selectedStrategy = this.getStrategyByValue(selectedStrategyValue);
    return selectedStrategy;
  }

  get selectedTestProtocol(): TestProtocol {
    const selectedProtocolValue = this.form.controls['protocol']?.value;
    const selectedProtocol = this.getProtocolByValue(selectedProtocolValue);
    return selectedProtocol;
  }

  get selectedLanguage(): string {
    return this.form.controls['language']?.value;
  }

  get selectedDevice(): Device {
    return this.form.controls['device']?.value;
  }

  get selectedControlType(): ControlType {
    const id = this.form.controls['control_type']?.value;
    return this.controlTypes.find(type => type.id === id);
  }

  get selectedEye(): string {
    return this.form.controls['eye']?.value;
  }

  get selectedMonocular(): boolean {
    return this.form.controls['monocular']?.value;
  }

  get selectedSubtitles(): boolean {
    return !!this.preferences.always_use_subtitles || this.form.controls['subtitles']?.value;
  }

  closeForm() {
    if (!this.isNew)
      return this.router.navigate(['/tests', (<Test>this.test).patient.id]);
    if (this.mwlItemId)
      return this.router.navigate(['/work-items']);

    return this.router.navigate(['/tests', this.patient.id]);
  }

  onSkipConvergenceChange(event: any) {
    this.form.controls.monocular.setValidators(event.target?.checked ? Validators.required : null);
    this.form.controls.monocular.updateValueAndValidity();
  }

  protected onStepOneComplete(isBundle?: boolean) {
    if (this.testFormService.shouldShowConfirmModal(this.form, this.controlTypes, this.selectedTestGroup, this.selectedTestStrategy, this.selectedTestProtocol) && !isBundle) {
      this.dialogService.openConfirm({
        action: this.translateService.instant('create'),
        message: this.translateService.instant('voiceConfirmMessage'),
        confirmText: this.translateService.instant('yes'),
        cancelText: this.translateService.instant('no'),
        showClose: false
      }).then(result => {
        if (result.confirmed)
          this.onStepOneConfirmed();
        if (result.canceled)
          this.cancelCreate();
      });
    } else {
      this.onStepOneConfirmed();
    }
  }

  private onStepOneConfirmed() {
    if (!this.isHomeDevice)
      return this.onSubmit(this.form);

    if (!this.patient.email && !this.patient.phone && this.patient?.id) {
      this.form.controls.patient_email.setValidators(Validators.compose([Validators.required, Validators.email]));
      this.form.controls.patient_phone.addValidators(Validators.required);
      this.form.controls.patient_phone.updateValueAndValidity();

      this.currentStep = 2;
      return;
    }

    this.currentStep = 3;
  }

  onStepTwoComplete() {
    const { patient_email, patient_phone } = this.form.value;

    this.patientsService.update(this.patient.id, {
      email: patient_email,
      phone: patient_phone,
    }).subscribe(res => this.currentStep = 3);
  }

  onPatientEmailChange(event) {
    if (event.target?.value)
      this.form.controls.patient_phone.removeValidators(Validators.required);
    else
      this.form.controls.patient_phone.addValidators(Validators.required);

    this.form.controls.patient_phone.updateValueAndValidity();
  }

  onPatientPhoneChange() {
    if (this.form.controls.patient_phone.valid)
      this.form.controls.patient_email.setValidators(Validators.email);
    else
      this.form.controls.patient_email.setValidators(Validators.compose([Validators.required, Validators.email]));

    this.form.controls.patient_email.updateValueAndValidity();
  }

  isStepTwoValid() {
    const { patient_email, patient_phone } = this.form.value;
    const hasValue = !!patient_email || !!patient_phone;
    const hasValidValue = this.form.controls.patient_email.valid && this.form.controls.patient_phone.valid;

    return hasValue && hasValidValue;
  }

  onRecurringConfirm() {
    this.isRecurringSelected = true;
    this.form.controls.end_date.setValidators(Validators.required);
    this.form.controls.period_in_days.setValidators(Validators.required);
  }

  onShortTutorialChange(event: any) {
    if (event.target?.checked)
      this.form.controls['skip_tutorial'].setValue(false);
  }

  onSkipTutorialChange(event: any) {
    if (event.target?.checked)
      this.form.controls['use_short_tutorial'].setValue(false);
  }

  onEyeChange() {
    this.form.controls['skip_convergence_testing']?.setValue(this.testFormService.isSingleEyeSelected(this.form));

    if (this.testFormService.isSingleEyeSelected(this.form) && this.form.controls['monocular']?.value === null)
      this.form.controls['monocular']?.setValue(false);
  }

  onMonocularCheckboxChange() {
    if ([STRATEGY.ESTERMAN, STRATEGY.CONTRAST_STANDARD].includes(this.selectedTestStrategy?.value)) {
      this.form.controls['eye']?.setValue(EYE.BOTH_CLIENT);
      return;
    }

    if (STRATEGY.CONTRAST_SINGLE_OPTOTYPE === this.selectedTestStrategy?.value || GROUP.VISUAL_ACUITY === this.selectedTestGroup.value) {
      this.form.controls['eye']?.setValue(!!this.selectedMonocular ? EYE.OD : EYE.BOTH_CLIENT);
      return;
    }
  }

  onControlTypeChange(id: number) {
    this.displayedLanguages = this.testFormService.getDisplayedLanguages(this.selectedDevice, this.selectedTestGroup, this.selectedTestStrategy, this.selectedControlType);
    this.isVoiceWarningDisplayed = this.controlTypes.find(type => type.id === id)?.name === CONTROL_TYPES.VOICE;
    this.checkSelectedLanguageValidity();
  }

  onCustomBundleChange(id: number) {
    this.isCustomBundleWithConvergence = this.displayedCustomBundleGroups
      .find(bundle => bundle.custom_test_bundle_info?.custom_test_bundle_id === id)?.custom_test_bundle_info?.has_convergence_test;
    if (!this.isCustomBundleWithConvergence) {
      this.form.controls['monocular']?.setValidators(null);
    } else if (this.form.controls['skip_convergence_testing']?.value)
      this.form.controls['monocular']?.setValidators(Validators.required);

    this.form.controls['monocular']?.updateValueAndValidity();
  }

  onTestDefaultClick() {
    this.isLoading = true;
    this.testDefaultService.createDefaultTest(this.patient.id, this.preferences)
      .then(() => this.isLoading = false)
      .catch(() => this.isLoading = false);
  }
}
