// native
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

// constants
import { WHITE_LABEL_SUBDOMAIN } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string): (group: UntypedFormGroup) => void {
    return (group: UntypedFormGroup) => {
      const password = group.controls[passwordKey];
      const confirmation = group.controls[passwordConfirmationKey];

      if (!password.value && !confirmation.value) {
        return confirmation.setErrors(null);
      } else if (password.value !== confirmation.value) {
        return confirmation.setErrors({ notEquivalent: true });
      } else {
        return confirmation.setErrors(null);
      }
    };
  }

  // Converts client local date to server yyyy-mm-dd string
  convertClientDateToServerDate(clientDate: string | Date): string {
    if (clientDate == null)
      return null;

    if (typeof clientDate === 'string')
      return clientDate;

    if (!clientDate.getFullYear && (<any>clientDate).toDate)
      clientDate = (<any>clientDate).toDate() as Date;

    const year = clientDate.getFullYear();

    const monthNum = clientDate.getMonth() + 1;
    let month = `${monthNum}`;
    if (monthNum <= 9)
      month = '0' + month;

    const dayNum = clientDate.getDate();
    let day = `${dayNum}`;
    if (dayNum <= 9)
      day = '0' + day;

    return year + '-' + month + '-' + day;
  }

  // Converts client local date to server yyyy-mm-ddThh:mm:ssZ UTC string
  convertClientDateToServerTimezoneDate(clientDate: string | Date): string {
    if (clientDate == null)
      return null;
    if (typeof clientDate === 'string')
      return clientDate;

    if (!clientDate.getFullYear && (<any>clientDate).toDate)
      clientDate = (<any>clientDate).toDate() as Date;

    return clientDate.toISOString();
  }

  // Converts server date (yyyy-mm-dd) string to Date object by ignoring time and timezone
  convertServerDateToClientDate(dateString: string): Date {
    if (dateString) {
      const localDate = new Date(dateString);
      return new Date(localDate.getUTCFullYear(), localDate.getUTCMonth(), localDate.getUTCDate());
    }
    return undefined;
  }

  // Converts server date (yyyy-mm-dd) string to string in client timezone (with time 00:00:00)
  convertServerDateToClientTimezoneDate(dateString: string): string {
    const agnosticDate = new Date(dateString);
    const offsetInMinutes = new Date().getTimezoneOffset();
    const dateInLocalTimezone = new Date(agnosticDate.getTime() + offsetInMinutes * 60000);
    return dateInLocalTimezone.toISOString();
  }

  // Returns client IANA timezone
  getLocalTimezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  calculateAge(dateOfBirth: string): number {
    if (!dateOfBirth)
      return null;
    var ageDifMs = Date.now() - (new Date(dateOfBirth)).getTime();
    var ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  appendQueryParam(url: string, name: string, value?: number | string) {
    if (value === undefined) {
      return url;
    }
    const separator = url.includes('?') ? '&' : '?';
    return `${url}${separator}${name}=${value}`;
  }

  get isWhiteLabelDomain() {
    const hostname = window.location.hostname;
    const parts = hostname.split('.');
    return parts.length >= 3 && parts[parts.length - 3]?.includes(WHITE_LABEL_SUBDOMAIN);
  }

  hasCommonElement(array1: number[], array2: number[]): boolean {
    const set1 = new Set(array1);

    for (let num of array2) {
      if (set1.has(num))
        return true;
    }

    return false;
  }

  escapeHTML(htmlStr: string) {
    return htmlStr.replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#39;");
  }
}