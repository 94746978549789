// native
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

// addon
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

// service
import { TestsService } from '../../core/services/tests.service';
import { TestBundlesService } from 'app/core/services/test-bundles.service';
import { PreferencesService } from 'app/core/services/preferences.service';
import { MonitorTestService } from 'app/core/services/monitor-test.service';
import { DevicesService } from 'app/core/services/devices.service';
import { AuthService } from 'app/core/services/auth.service';
import { PatientsService } from 'app/core/services/patients.service';
import { StreamingService } from 'app/core/services/streaming.service';
import { DialogService } from 'app/core/services/dialog.service';

// component
import { MonitorBaseComponent } from '../monitor-base.component';

// models
import { AcuityOptotypeRecord, DeviceScreen, MonitorEvent } from '../../models';

//animation
import { fadeAnimation, startList } from '../../animations/animations';

// constant
import { MONITOR_EVENT_TYPE, EYE, GROUP, TEST_BUNDLE_TYPES, MONITOR_INACTIVITY_TIMEOUT, TEST_ACTION, DEVICE_SCREEN } from '../../constants';

@Component({
  selector: 'app-monitor-visual-acuity-test',
  templateUrl: './monitor-acuity-test.component.html',
  animations: [fadeAnimation, startList]
})
export class MonitorVisualAcuityComponent extends MonitorBaseComponent {

  odData: AcuityOptotypeRecord[] = [];
  osData: AcuityOptotypeRecord[] = [];
  ouData: AcuityOptotypeRecord[] = [];

  osLastElementIndex: number = 0;
  ouLastElementIndex: number = 0;

  hasSeqNum: boolean = false;
  odPhaseSyncing: boolean = false;
  osPhaseSyncing: boolean = false;

  odThresholdSeqNum: number;
  osThresholdSeqNum: number;
  ouThresholdSeqNum: number;

  isContrastTest: boolean = false;
  isInsideCSuite: boolean = false;
  isLoading: boolean = true;

  isSingleEyeTest: boolean = false;
  isOdEyeShown: boolean = true;
  isOsEyeShown: boolean = true;

  odPhaseSkippable: boolean = false;
  osPhaseSkippable: boolean = false;
  ouPhaseSkippable: boolean = false;

  odPhaseSkipped: boolean = false;
  osPhaseSkipped: boolean = false;
  ouPhaseSkipped: boolean = false;

  inactivityTimeout = MONITOR_INACTIVITY_TIMEOUT.VISUAL_ACUITY * 1000;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public testService: TestsService,
    public testBundlesService: TestBundlesService,
    public toastService: ToastrService,
    public devicesService: DevicesService,
    public authService: AuthService,
    public patientsService: PatientsService,
    public monitorTestService: MonitorTestService,
    public streamingService: StreamingService,
    public preferencesService: PreferencesService,
    public translateService: TranslateService,
    public dialogService: DialogService) {
    super(route, router, testService, testBundlesService, toastService, devicesService, authService, patientsService,
      monitorTestService, streamingService, preferencesService, translateService, dialogService);
  }

  public getCurrentTestState() {
    this.isSingleEyeTest = [EYE.OS, EYE.OD].includes(this.test?.eye);
    this.isOdEyeShown = !this.test?.eye || this.test.eye === EYE.OD;
    this.isOsEyeShown = !this.test?.eye || this.test.eye === EYE.OS;

    this.monitorTestService.getAcuityState(this.test.id)
      .pipe(
        finalize(() => this.isLoading = false)
      ).subscribe(records => {
        this.renderRecords(records);

        this.isContrastTest = this.test.test_group?.group?.value === GROUP.CONTRAST_SENSITIVITY;
        this.isInsideCSuite = this.test.test_bundle?.system_test_bundle_type === this.testBundlesService.testBundleTypes
          .find(type => type.name === TEST_BUNDLE_TYPES.C_SUITE)?.id;
      });;
  }

  private renderRecords(records: AcuityOptotypeRecord[]) {
    this.hasSeqNum = !!records[0]?.seq_num;
    if (this.hasSeqNum) {
      records.filter(record => record.eye === EYE.OD).forEach(record => {
        if (!!record.skipped)
          this.odPhaseSkipped = true;
        this.odData[record.seq_num - 1] = record;
      });
      records.filter(record => record.eye === EYE.OS).forEach(record => {
        if (!!record.skipped)
          this.osPhaseSkipped = true;
        this.osData[record.seq_num - 1] = record;
      });
      records.filter(record => record.eye === EYE.OU).forEach(record => {
        if (!!record.skipped)
          this.ouPhaseSkipped = true;
        this.ouData[record.seq_num - 1] = record;
      });

      this.osLastElementIndex = this.getLastElementIndex(this.osData);
      this.ouLastElementIndex = this.getLastElementIndex(this.ouData);

      this.odThresholdSeqNum = this.odData.find(record => record?.threshold)?.seq_num;
      this.osThresholdSeqNum = this.osData.find(record => record?.threshold)?.seq_num;
      this.ouThresholdSeqNum = this.ouData.find(record => record?.threshold)?.seq_num;

      this.odPhaseSyncing = this.hasSeqNum && !this.odPhaseComplete() && (!!this.osData.length || !!this.ouData.length);
      this.osPhaseSyncing = this.hasSeqNum && !this.osPhaseComplete() && !!this.ouData.length;

    } else {
      this.odData = records.filter(record => record.eye === EYE.OD);
      this.osData = records.filter(record => record.eye === EYE.OS);
      this.ouData = records.filter(record => record.eye === EYE.OU);
    }

    this.calculateSkippable();
  }

  public getPercentageDone() {
    if (this.isInsideCSuite)
      return this.getCSuitePercentage();
    if (this.isSingleEyeTest)
      return this.getSingleEyePercentage();
    return this.getRegularPercentage();
  }

  private getCSuitePercentage() {
    if (this.isTestDone)
      return 100;
    else if (this.osData?.length)
      return 50;
    return 0;
  }

  private getSingleEyePercentage() {
    if (this.isTestDone)
      return 100;
    return 0;
  }

  private getRegularPercentage() {
    if (this.isTestDone)
      return 100;
    else if (this.ouData?.length)
      return 67;
    else if (this.osData?.length)
      return 33;
    return 0;
  }

  public calculateDimensions() { }

  public handleNewRecordEvent(event: MonitorEvent) {
    if (event.type === MONITOR_EVENT_TYPE.NEW_ACUITY_RECORD)
      this.updateRecords(<AcuityOptotypeRecord>event.data);
  }

  private updateRecords(record: AcuityOptotypeRecord) {
    switch (record.eye) {
      case EYE.OD:
        if (record.skipped)
          this.odPhaseSkipped = true;
        this.updateEyeRecord(record, this.odData);
        break;
      case EYE.OS:
        if (record.skipped)
          this.osPhaseSkipped = true;
        this.updateEyeRecord(record, this.osData);
        this.osLastElementIndex = this.getLastElementIndex(this.osData);
        break;
      case EYE.OU:
        if (record.skipped)
          this.ouPhaseSkipped = true;
        this.updateEyeRecord(record, this.ouData);
        this.ouLastElementIndex = this.getLastElementIndex(this.ouData);
        break;
    }
    this.hasSeqNum = !!record.seq_num;

    this.odThresholdSeqNum = this.odData.find(record => record?.threshold)?.seq_num;
    this.osThresholdSeqNum = this.osData.find(record => record?.threshold)?.seq_num;
    this.ouThresholdSeqNum = this.ouData.find(record => record?.threshold)?.seq_num;

    this.odPhaseSyncing = this.hasSeqNum && !this.odPhaseComplete() && (!!this.osData.length || !!this.ouData.length);
    this.osPhaseSyncing = this.hasSeqNum && !this.osPhaseComplete() && !!this.ouData.length;

    this.calculateSkippable();
  }

  private updateEyeRecord(record: AcuityOptotypeRecord, eyeData: AcuityOptotypeRecord[]) {
    if (!record?.seq_num)
      eyeData.push(record);
    else if (!eyeData[0] || (record.seq_num > eyeData[0].seq_num))
      eyeData[record.seq_num - 1] = record;
  }

  private getLastElementIndex(eyeData: AcuityOptotypeRecord[]): number {
    return eyeData.length - 1 - eyeData.findIndex(el => !!el);
  }

  private odPhaseComplete(): boolean {
    return !!this.odThresholdSeqNum && (this.odData.filter(r => !!r).length >= this.odThresholdSeqNum);
  }

  private osPhaseComplete(): boolean {
    if (!this.osThresholdSeqNum || !this.odThresholdSeqNum)
      return false;
    const osDataLength = this.osThresholdSeqNum - this.odThresholdSeqNum;
    return this.osData.filter(r => !!r).length >= osDataLength;
  }

  isOsRecordHidden(record: AcuityOptotypeRecord, index: number) {
    if (record && (record.threshold || record.skipped) && record.seq_num)
      return true;

    if (record)
      return false;

    if (!this.odThresholdSeqNum)
      return index > this.osLastElementIndex;

    const firstRecordIndex = this.osData.length - 1 - this.odThresholdSeqNum;
    return index > firstRecordIndex;
  }

  isOuRecordHidden(record: AcuityOptotypeRecord, index: number) {
    if (record && (record.threshold || record.skipped) && record.seq_num)
      return true;

    if (record)
      return false;

    if (!this.osThresholdSeqNum)
      return index > this.ouLastElementIndex;

    const firstRecordIndex = this.ouData.length - 1 - this.osThresholdSeqNum;
    return index > firstRecordIndex;
  }

  public resetStateAfterReconnection(): void {
    this.odData = [];
    this.osData = [];
    this.ouData = [];

    this.osLastElementIndex = 0;
    this.ouLastElementIndex = 0;

    this.odThresholdSeqNum = null;
    this.osThresholdSeqNum = null;
  }

  public onToggleGrid() { }


  protected updateDeviceScreenStatus(deviceScreen: DeviceScreen) {
    super.updateDeviceScreenStatus(deviceScreen);
    this.calculateSkippable();
  }

  onSkipEye(eye: 'OS' | 'OD' | null) {
    this.monitorTestService.sendActionToDevice(this.test?.device, { message: TEST_ACTION.SKIP_EYE.value, payload: eye })
      .subscribe();
  }

  private calculateSkippable() {
    const isSkippable =
      (this.currentDeviceScreen?.name === DEVICE_SCREEN.DOING_TEST.value)
      && this.test?.test_group?.group?.value === GROUP.VISUAL_ACUITY
      && this.device.capabilities.visual_acuity_algorithm_version > 1
      && !this.isTestDone
      && !this.isTestSyncing;

    if ([EYE.OD, EYE.OS].includes(this.test.eye)) {
      this.odPhaseSkippable = isSkippable;
      this.osPhaseSkippable = isSkippable;
      return;
    }

    const isOdCurrentlyTested = !this.odThresholdSeqNum && !this.osData.length && !this.ouData.length;
    const isOsCurrentlyTested = !isOdCurrentlyTested && !this.osThresholdSeqNum && !this.ouData.length;
    const isOuCurrentlyTested = !isOdCurrentlyTested && !isOsCurrentlyTested && !this.ouThresholdSeqNum;

    this.odPhaseSkippable = isSkippable && isOdCurrentlyTested;
    this.osPhaseSkippable = isSkippable && isOsCurrentlyTested;
    this.ouPhaseSkippable = isSkippable && isOuCurrentlyTested;
  }
}