// native
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TitleCasePipe } from '@angular/common';

// addon
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

// services
import { UsersService } from 'app/core/services/users.service';
import { WebAuthnService } from 'app/core/services/webauthn.service';
import { DialogService } from 'app/core/services/dialog.service';
import { TabsService } from 'app/core/services/tabs.service';
import { AuthService } from 'app/core/services/auth.service';

// models
import { IEmployee, PaginatedItems, Tab, ListItemColumn, ListItemAction } from '../models';

// constants
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, OFFICE_TAB_INDEX, USER_GROUP } from '../constants';

interface IEmployeeDisplay extends IEmployee {
  user_group: string;
}

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html'
})
export class UsersComponent implements OnInit, OnDestroy {

  users$: BehaviorSubject<PaginatedItems<IEmployeeDisplay> | null> = new BehaviorSubject(null);

  tabs: Tab[] = [];

  columns: ListItemColumn[] = [
    {
      translationKey: 'firstName',
      fieldName: 'first_name',
      fontClass: 'bold'
    },
    {
      translationKey: 'lastName',
      fieldName: 'last_name',
      fontClass: 'bold'
    },
    {
      translationKey: 'email',
      fieldName: 'email',
      fontClass: 'bold'
    },
    {
      translationKey: 'userGroup',
      fieldName: 'user_group'
    }
  ];

  actions: ListItemAction[] = [
    {
      translationKey: 'edit',
      execute: (user: IEmployee) => this.edit(user),
      visible: () => true
    },
    {
      translationKey: 'delete',
      execute: (user: IEmployee) => this.delete(user),
      visible: (user: IEmployee) => this.canDelete(user)
    },
    {
      translationKey: 'manageOffices',
      execute: (user: IEmployee) => this.manageOffices(user),
      visible: (user: IEmployee) => this.canManageOffices(user)
    },
    {
      translationKey: 'resetPassword',
      execute: (user: IEmployee) => this.resetPassword(user),
      visible: () => !this.authService.isSsoEnabled
    }
  ];

  currentPageSize: number = DEFAULT_PAGE_SIZE;
  currentPageIndex: number = DEFAULT_PAGE_INDEX;
  term: string;

  isLoading: boolean = false;

  languageSubscription: Subscription;

  constructor(
    private router: Router,
    private usersService: UsersService,
    public authService: AuthService,
    private toastService: ToastrService,
    private translateService: TranslateService,
    private webAuthnService: WebAuthnService,
    private dialogService: DialogService,
    private tabsService: TabsService
  ) { }

  ngOnInit() {
    this.getUsers(this.currentPageSize, this.currentPageIndex, null);

    this.languageSubscription = this.translateService.onLangChange.subscribe(res => {
      this.getUsers(this.currentPageSize, this.currentPageIndex, null);
    });

    this.tabs = this.tabsService.getOfficeTabs(OFFICE_TAB_INDEX.USERS);
  }

  ngOnDestroy(): void {
    this.languageSubscription?.unsubscribe();
  }

  getUsers(pageSize: number, pageIndex: number, term: string) {
    this.isLoading = true;
    this.usersService.getAllPaginated(pageSize, pageIndex, term).subscribe((users: PaginatedItems<IEmployee>) => {
      this.isLoading = false;
      this.users$.next(this.parseUsers(users));
    }, err => { this.isLoading = false; });
  }

  search(term: string) {
    this.currentPageIndex = 0;
    this.term = term;

    this.getUsers(this.currentPageSize, this.currentPageIndex, this.term);
  };

  edit(user: IEmployee) {
    this.router.navigate(['/users/edit', user.id]);
  }

  private canDelete(user: IEmployee): boolean {
    if (user.groups?.includes(USER_GROUP.OFFICE_GROUP_ADMIN.value))
      return false;
    if (this.authService.isOfficeAdmin && user.groups?.includes(USER_GROUP.OFFICE_ADMIN.value))
      return false;
    return true;
  }

  private canManageOffices(user: IEmployee): boolean {
    if (this.authService.isOfficeGroupAdmin)
      return true;
    return this.authService.isOfficeAdmin && !user.groups?.includes(USER_GROUP.OFFICE_ADMIN.value);
  }


  delete(user: IEmployee) {
    this.dialogService.openConfirm({
      action: this.translateService.instant('delete'),
      message: this.translateService.instant('deleteUserPrompt')
    }).then(result => {
      if (result.confirmed)
        this.usersService.delete(user.id).subscribe(
          res => this.getUsers(this.currentPageSize, this.currentPageIndex, this.term));
    });
  }

  create() {
    this.router.navigate(['/users/new']);
  }

  manageOffices(user: IEmployee) {
    this.router.navigate(['/offices/select', user.id]);
  }

  resetPassword(user: IEmployee) {
    this.webAuthnService.wrapAction(
      () => this.resetPasswordAction(user),
      err => this.toastService.error(this.translateService.instant('issueResetingPassword'))
    );
  }

  private resetPasswordAction(user: IEmployee) {
    this.usersService.resetPassword(user.id).subscribe(res => {
      this.toastService.success(this.translateService.instant('passwordIsReset'));
    }, err => {
      this.toastService.error(this.translateService.instant('issueResetingPassword'));
    });
  }

  changePage(pageIndex: number, pageSize: number) {
    this.currentPageIndex = pageIndex;
    this.currentPageSize = pageSize;
    this.getUsers(pageSize, pageIndex, this.term);
  }

  private parseUsers(users: PaginatedItems<IEmployee>): PaginatedItems<IEmployeeDisplay> {
    const parsedUsers = users.results.map(user => {
      return {
        ...user,
        user_group: user.groups
          .filter(g => USER_GROUP[g])
          .map(g => new TitleCasePipe().transform(this.translateService.instant(USER_GROUP[g]?.translationKey || ''))).slice(0, 3).join(', ')
      };
    });

    return {
      results: parsedUsers,
      count: users.count
    };
  }
}
