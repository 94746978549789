// auth
export const API_LOGIN_PATH = `/api/auth-token/`;
export const API_LOGOUT_PATH = `/api/logout/`;
export const API_DUO_VERIFY_PATH = `/api/duo-verify/`;
export const API_UPDATE_USER = `/api/update-user/`;
export const API_START_RESET_PASSWORD_PROCESS_PATH = `/api/send-reset-password/`;
export const API_VALIDATE_RESET_PASSWORD_REQUEST_PATH = `/api/validate-reset-password/`;
export const API_RESET_PASSWORD_PATH = `/api/reset-password/`;

// webauthn
export const API_WEBAUTHN_AUTHENTICATOR = `/api/webauthn/authenticators/`;
export const API_WEBAUTHN_GET_CREDENTIALS = `/api/webauthn/authentication/`;
export const API_WEBAUTHN_VALIDATE_CREDENTIALS = `/api/webauthn/authentication/request/`;
export const API_WEBAUTHN_LOGIN_VERIFY = `/api/webauthn-verify/`;
export const API_WEBAUTHN_REGISTER = `/api/webauthn/registration/`;

//sso
export const API_SSO_PATH = `/api/auth-sso/authorize/`;
export const API_BOOTSTRAP_PATH = `/api/bootstrap/`;
export const API_INVITATIONS_PATH = `/api/sso-invitation/`;
export const API_SSO_INVITE_PATH = `/api/employees/sso/`;

// patients
export const API_PATIENTS_PATH = `/api/patients/`;
export const API_GENDERS_PATH = `/api/genders/`;
export const API_ETHNICITIES_PATH = `/api/ethnicities/`;
export const API_DEMO_PATIENT_PATH = `/api/potential-customer/`;

// devices
export const API_DEVICES_PATH = `/api/devices/`;
export const API_DEVICES_TEST_CANDIDATES_PATH = `/api/devices/test-create-candidates/`;
export const API_ACTIVE_DEVICES_PATH = `/api/devices/get_active_devices_detail/`;

// doctors
export const API_DOCTORS_PATH = `/api/doctors/`;

// offline controllers
export const API_OFFLINE_CONTROLLERS_PATH = `/api/offline-controllers/`;

// operators
export const API_OPERATORS_PATH = `/api/operators/`;

// preferences
export const API_GET_PREFERENCES_PATH = `/api/preferences/`;
export const API_UPDATE_PREFERENCES_PATH = `/api/update-preferences/`;

// tests
export const API_TESTS_PATH = `/api/tests/`;
export const API_PATIENT_TESTS_PATH = `/api/tests/patient-tests/`;
export const API_TEST_GROUPS_PATH = `/api/test-groups/`;
export const API_CORRECTION_TYPES_PATH = `/api/correctiontypes/`;
export const API_STIMULUS_TYPES_PATH = `/api/stimulustypes/`;
export const API_CONTROL_TYPES_PATH = `/api/controltypes/`;
export const API_TEST_LANGUAGES_PATH = `/api/languages/`;
export const API_TEST_SYNC_PROGRESS_PATH = `/api/tests/sync_progress/`;

// test bundles
export const API_TEST_BUNDLES_PATH = `/api/testbundles/`;
export const API_TEST_BUNDLE_TYPES_PATH = `/api/systemtestbundletypes/`;

// recurring tests
export const API_TESTS_RECURRING_PATH = `/api/testschedules/`;

// custom bundles
export const API_CUSTOM_BUNDLES_PATH = `/api/custom-test-bundle-types/`;

// users
export const API_USERS_PATH = `/api/employees/`;

// offices
export const API_OFFICES_PATH = `/api/offices/`;

// office groups
export const API_SWITCH_OFFICE_GROUP_PATH = `/api/switch-office-group/`;

// reports
export const API_TEST_REPORT_HTML_PATH = `/api/reports/test-result/html/`;
export const API_TEST_REPORT_PDF_PATH = `/api/reports/test-result/pdf/`;
export const API_TEST_REPORT_IMAGE_PATH = `/api/reports/test-result/image/`;
export const API_TEST_REPORT_DICOM_PATH = `/api/reports/test-result/dicom/`;
export const API_TEST_REPORT_EMAIL_PATH = `/api/reports/test-result/email/`;
export const API_TEST_BUNDLE_HTML_REPORT_PATH = `/api/reports/testbundle/html/`;
export const API_TEST_BUNDLE_PDF_REPORT_PATH = `/api/reports/testbundle/pdf/`;
export const API_TEST_BUNDLE_IMAGE_REPORT_PATH = `/api/reports/testbundle/image/`;
export const API_TEST_BUNDLE_DICOM_REPORT_PATH = `/api/reports/testbundle/dicom/`;
export const API_TEST_BUNDLE_REPORT_MODE_PATH = `/api/reports/testbundle/modes/`;
export const API_TREND_ANALYSIS_REPORT_PATH = `/api/reports/trend-analysis/`;

// monitor
export const API_PERIMETRY_STATE_PATH = `/api/perimetry/currentstate/`;
export const API_ACUITY_STATE_PATH = `/api/acuityoptotyperesults/`;
export const API_EOMS_STATE_PATH = `/api/eomsresponse/`;
export const API_COLOR_VISION_STATE_PATH = `/api/colorvision/currentstate/`;
export const API_ISHIHARA_STATE_PATH = `/api/ishihara/currentstate/`;
export const API_WAGGONER_STATE_PATH = `/api/waggoner/currentstate/`;
export const API_PUPILLOMETRY_STATE_PATH = `/api/pupillometry/currentstate/`;
export const API_COVER_STATE_PATH = `/api/cover/currentstate/`;
export const API_SENSORIMOTOR_STATE_PATH = `/api/sensorimotor/currentstate/`;
export const API_CONTRAST_STANDARD_STATE_PATH = `api/v3/contrast-sensitivity/responses/`;

// import
export const API_GET_ALL_IMPORTS_PATH = `/api/tests-external/patient-tests/`;
export const API_UPLOAD_REPORT_PATH = `/api/tests-external/`;
export const API_IMPORT_GROUPS_PATH = `/api/test-external/test-groups/`;

// support
export const API_SUPPORT_REPORTS_PATH = `/api/support/reports/`;
export const API_OFFICE_GROUP_PATH = `/api/office-groups/`;
export const API_SUPPORT_USERS_PATH = `/api/users/`;

// techall
export const API_TECHALL_SESSION_PATH = `/api/techall-sessions/`;

// contact
export const API_CONTACT_PATH = `/api/support/`;

// pacs
export const API_PACS_INTEGRATION_QUERY_PATH = `/api/pacs-integration/mwl-query/`;
export const API_PACS_INTEGRATION_CONFIGURATION_PATH = `/api/pacs-integration/configuration/`;
export const API_PACS_INTEGRATION_RECORDS_PATH = `/api/pacs-integration/`;

// BAA agreement
export const API_BAA_AGREEMENT_PATH = `/api/sign-baa-agreement/`;

// websockets
export const WEBSOCKET_MONITOR_TESTS = `/live/tests/`;
export const WEBSOCKET_IMPORT_STATUS = `/live/tests_external/`;
export const WEBSOCKET_PACS_FILTER = `/live/pacs-integration/`;