// native
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// addon
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from "ngx-toastr";

// service
import { TestsService } from 'app/core/services/tests.service';
import { DevicesService } from 'app/core/services/devices.service';
import { AuthService } from 'app/core/services/auth.service';
import { PatientsService } from 'app/core/services/patients.service';
import { MonitorTestService } from 'app/core/services/monitor-test.service';
import { StreamingService } from 'app/core/services/streaming.service';
import { TestBundlesService } from 'app/core/services/test-bundles.service';
import { PreferencesService } from 'app/core/services/preferences.service';
import { DialogService } from 'app/core/services/dialog.service';

// component
import { MonitorBaseComponent } from '../monitor-base.component';

// models
import { MonitorEvent } from '../../models';

// constant
import { MONITOR_EVENT_TYPE, MONITOR_INACTIVITY_TIMEOUT, GROUP } from '../../constants';

@Component({
  selector: 'app-monitor-image',
  templateUrl: './monitor-image.component.html'
})
export class MonitorImageComponent extends MonitorBaseComponent {

  ongoingPercentage: number;
  isOngoingImageTest: boolean = false;
  public inactivityTimeout = MONITOR_INACTIVITY_TIMEOUT.PERIMETRY * 1000;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public testService: TestsService,
    public testBundlesService: TestBundlesService,
    public toastService: ToastrService,
    public devicesService: DevicesService,
    public authService: AuthService,
    public patientsService: PatientsService,
    public monitorTestService: MonitorTestService,
    public streamingService: StreamingService,
    public preferencesService: PreferencesService,
    public translateService: TranslateService,
    public dialogService: DialogService) {
    super(route, router, testService, testBundlesService, toastService, devicesService, authService,
      patientsService, monitorTestService, streamingService, preferencesService, translateService, dialogService);
  }

  public getCurrentTestState() {
    this.isOngoingImageTest = this.test.test_group?.group?.value === GROUP.ONGOING_EYE_IMAGE_CAPTURE;
    if (this.isOngoingImageTest)
      this.monitorTestService.getProgress(this.test.id).subscribe(progress => {
        this.ongoingPercentage = progress;
      });
  }

  public handleNewRecordEvent(event: MonitorEvent) {
    if (event.type === MONITOR_EVENT_TYPE.PROGRESS_UPDATE_RECORD) {
      this.ongoingPercentage = <number>event.data;
    }
  }

  public calculateDimensions() { }

  public onToggleGrid() { }

  public resetStateAfterReconnection() { }

  getPercentage(): number {

    return this.isTestDone
      ? 100
      : this.isOngoingImageTest
        ? this.ongoingPercentage
        : 0;
  }
}
