
// native
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

// addon
import * as cbor from 'cbor-web';

// services
import { TrackingService } from './tracking.service';
import { ZeroPiiService } from './zero-pii.service';
import { DemoService } from './demo.service';
import { PreferencesService } from './preferences.service';

// models
import {
  User, UserLoginRequest, UserGroupItem, UserUpdateRequest, DuoAuthResponse,
  DuoVerifyRequest, UserAuthResponse, WebAuthnResponse, OfficeGroupItem
} from '../../models';

// constants
import {
  API_LOGIN_PATH, API_LOGOUT_PATH, API_UPDATE_USER, API_DUO_VERIFY_PATH,
  BETA_LEVELS, USER_GROUP, AUTH_STORAGE_KEYS, API_WEBAUTHN_LOGIN_VERIFY, ENABLE_ZERO_PII_KEY, API_BOOTSTRAP_PATH,
  API_SWITCH_OFFICE_GROUP_PATH
} from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private trackingService: TrackingService,
    private zeroPiiService: ZeroPiiService,
    private demoService: DemoService,
    private preferencesService: PreferencesService
  ) { }

  login(user: UserLoginRequest): Observable<void | DuoAuthResponse | WebAuthnResponse> {
    return this.httpClient.post(API_LOGIN_PATH, user).pipe(map(res => res as void | DuoAuthResponse | WebAuthnResponse));
  }

  handleLoginSuccess() {
    this.bootstrapUser().subscribe((authResponse: UserAuthResponse) => {
      this.storeUserData(authResponse);

      if (authResponse?.user?.force_password_change_on_next_login) {
        return this.router.navigate(['/reset-password']);
      }

      this.preferencesService.get().subscribe(() => {
        if (authResponse?.user?.show_baa_agreement) {
          return this.router.navigate(['/baa-agreement']);
        }
        if (authResponse?.office_group?.enable_zero_pii) {
          return this.checkZeroPiiKey();
        }
        if (this.isTechallUser) {
          return this.router.navigate(['/techall-mode']);
        }
        if (this.isDemoModeAvailable) {
          return this.demoService.checkAndRedirect(true);
        }
        return this.router.navigate(['/patients']);
      });
    });
  }

  bootstrapUser(): Observable<UserAuthResponse> {
    return this.httpClient.get(`${API_BOOTSTRAP_PATH}`).pipe(map(res => res as UserAuthResponse));
  }

  private checkZeroPiiKey() {
    this.zeroPiiService.loadKey().then(key => {
      if (key)
        return this.router.navigate(['/patients']);
      else
        this.router.navigate(['/zero-pii-prompt']);
    });
  }

  logout() {
    return this.httpClient
      .post(API_LOGOUT_PATH, null).subscribe(res => {
        this.clearLocalData();
        this.router.navigate(['/login']);
      }, err => {
        this.clearLocalData();
        this.router.navigate(['/login']);
      });
  }

  verifyDuoLogin(body: DuoVerifyRequest): Observable<void | WebAuthnResponse> {
    return this.httpClient
      .post(API_DUO_VERIFY_PATH, body).pipe(map(res => res as void | WebAuthnResponse));
  }

  verifyWebAuthnLogin(credentials: PublicKeyCredential): Observable<Object> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/cbor'
      })
    };

    const payload = cbor.encode({
      "credentialId": credentials.rawId,
      "authenticatorData": (<any>credentials.response).authenticatorData,
      "clientDataJSON": credentials.response.clientDataJSON,
      "signature": (<any>credentials.response).signature
    });

    return this.httpClient.post(API_WEBAUTHN_LOGIN_VERIFY, payload.buffer, httpOptions).pipe(map(res => res as Object));
  }

  getCurrentUser(): User {
    return {
      username: this.getUsername(),
      first_name: this.getFirstName(),
      last_name: this.getLastName(),
      email: this.getEmail(),
      phone_number: this.getPhoneNumber(),
      id: this.getId(),
      groups: this.getUserGroup(),
      administrated_offices: this.getUserAdministeredOffices()
    };
  }

  private getEmail(): string {
    return localStorage.getItem(AUTH_STORAGE_KEYS.USER_EMAIL);
  }

  getPhoneNumber(): string {
    return localStorage.getItem(AUTH_STORAGE_KEYS.USER_PHONE_NUMBER);
  }

  getUserGroup(): UserGroupItem[] {
    const storedGroup = localStorage.getItem(AUTH_STORAGE_KEYS.USER_GROUP);
    if (!storedGroup)
      return null;
    return JSON.parse(storedGroup);
  }

  getUserAdministeredOffices(): number[] {
    const storedOffices = localStorage.getItem(AUTH_STORAGE_KEYS.USER_ADMINISTERED_OFFICES);
    if (!storedOffices)
      return [];
    return JSON.parse(storedOffices);
  }

  getOfficeGroups(): OfficeGroupItem[] {
    const storedGroups = localStorage.getItem(AUTH_STORAGE_KEYS.OFFICE_GROUPS);
    if (!storedGroups)
      return null;
    return JSON.parse(storedGroups);
  }

  getUsername(): string {
    return localStorage.getItem(AUTH_STORAGE_KEYS.USER_NAME);
  }

  get isLoggedIn(): boolean {
    return !!localStorage.getItem(AUTH_STORAGE_KEYS.USER_ID);
  }

  get isOfficeGroupAdmin(): boolean {
    return this.hasGroup(USER_GROUP.OFFICE_GROUP_ADMIN.value);
  }

  get isOfficeAdmin(): boolean {
    return this.hasGroup(USER_GROUP.OFFICE_ADMIN.value);
  }

  get isCorporateAdmin(): boolean {
    return this.hasGroup(USER_GROUP.CORPORATE_ADMIN.value);
  }

  get isTechallUser(): boolean {
    return this.hasGroup(USER_GROUP.TECHALL_USER.value);
  }

  get isTechallAdmin(): boolean {
    return this.hasGroup(USER_GROUP.TECHALL_ADMIN.value);
  }

  get isTechallUserOrAdmin(): boolean {
    return this.isTechallAdmin || this.isTechallUser;
  }

  get isBasicSupportUser(): boolean {
    return this.hasGroup(USER_GROUP.OLLEYES_BASIC_SUPPORT.value);
  }

  get isReadonlySupportUser(): boolean {
    return this.hasGroup(USER_GROUP.OLLEYES_READONLY_SUPPORT.value);
  }

  get isSupportUser(): boolean {
    return this.isBasicSupportUser || this.isReadonlySupportUser;
  }

  private hasGroup(group: string): boolean {
    const userGroups = this.getUserGroup();
    return userGroups ? userGroups.filter(x => x.name === group).length > 0 : false;
  }

  get isOneHundredBetaLevel(): boolean {
    return this.getBetaLevel() >= BETA_LEVELS.ONE_HUNDRED;
  }

  get isD15ColorVisionModuleEnabled(): boolean {
    return !!JSON.parse(localStorage.getItem(AUTH_STORAGE_KEYS.COLOR_VISION_D_15_MODULE));
  }

  get isSupraFastModuleEnabled(): boolean {
    return !!JSON.parse(localStorage.getItem(AUTH_STORAGE_KEYS.SUPRAFAST_MODULE));
  }

  get isDemoModeAvailable(): boolean {
    return !!JSON.parse(localStorage.getItem(AUTH_STORAGE_KEYS.DEMO_MODE));
  }

  get isZeroPiiAvailable(): boolean {
    return !!JSON.parse(localStorage.getItem(AUTH_STORAGE_KEYS.ENTERPRISE_SECURITY)) && this.isOfficeGroupAdmin;
  }

  get isOfflineModeAvailable(): boolean {
    return !!JSON.parse(localStorage.getItem(AUTH_STORAGE_KEYS.OFFLINE_MODE));
  }

  get isResearchModeEnabled(): boolean {
    return !!JSON.parse(localStorage.getItem(AUTH_STORAGE_KEYS.RESEARCH_MODE));
  }

  get isSsoEnabled(): boolean {
    return !!JSON.parse(localStorage.getItem(AUTH_STORAGE_KEYS.ENABLE_SSO));
  }

  get ssoDomain(): string {
    return localStorage.getItem(AUTH_STORAGE_KEYS.SSO_DOMAIN);
  }

  get isWebAuthnAuthenticationRequired(): boolean {
    return !!JSON.parse(localStorage.getItem(AUTH_STORAGE_KEYS.IS_WEBAUTHN_AUTHENTICATION_REQUIRED));
  }

  get isPacsIntegrationUser(): boolean {
    return !!JSON.parse(localStorage.getItem(AUTH_STORAGE_KEYS.PACS_INTEGRATION));
  }

  get shouldShowBaaAgreement(): boolean {
    return !!JSON.parse(localStorage.getItem(AUTH_STORAGE_KEYS.SHOW_BAA_AGREEMENT));
  }

  private getFirstName(): string {
    return localStorage.getItem(AUTH_STORAGE_KEYS.USER_FIRST_NAME);
  }

  private getLastName(): string {
    return localStorage.getItem(AUTH_STORAGE_KEYS.USER_LAST_NAME);
  }

  getId(): number {
    return parseInt(localStorage.getItem(AUTH_STORAGE_KEYS.USER_ID), 10);
  }

  getBetaLevel(): number {
    return parseInt(localStorage.getItem(AUTH_STORAGE_KEYS.BETA_LEVEL));
  }

  shouldForceResetPassword(): boolean {
    return localStorage.getItem(AUTH_STORAGE_KEYS.FORCE_PASSWORD_CHANGE) === 'true';
  }

  get doctorId(): number {
    if (!localStorage.getItem(AUTH_STORAGE_KEYS.DOCTOR_ID))
      return null;
    return parseInt(localStorage.getItem(AUTH_STORAGE_KEYS.DOCTOR_ID), 10);
  }

  get operatorId(): number {
    if (!localStorage.getItem(AUTH_STORAGE_KEYS.OPERATOR_ID))
      return null;
    return parseInt(localStorage.getItem(AUTH_STORAGE_KEYS.OPERATOR_ID), 10);
  }

  public get storedOfficeGroupId(): number {
    if (!localStorage.getItem(AUTH_STORAGE_KEYS.OFFICE_GROUP_ID))
      return null;
    return parseInt(localStorage.getItem(AUTH_STORAGE_KEYS.OFFICE_GROUP_ID), 10);
  }

  storeUserData(authResponse: UserAuthResponse) {
    const { username, email, phone_number, id, groups, first_name, last_name, force_password_change_on_next_login, doctor_id, operator_id,
      is_webauthn_authentication_required, show_baa_agreement, administrated_offices } = authResponse.user;

    const { beta_level, color_vision_d_15_module, suprafast_module, demo_mode, research_mode, pacs_integration,
      enterprise_security, enable_sso, sso_domain, enable_zero_pii, enable_offline_mode, corporation } = authResponse.office_group;

    const officeGroupId = authResponse.office_group.id;

    localStorage.setItem(AUTH_STORAGE_KEYS.USER_NAME, username);
    localStorage.setItem(AUTH_STORAGE_KEYS.USER_EMAIL, email);
    localStorage.setItem(AUTH_STORAGE_KEYS.USER_PHONE_NUMBER, phone_number || '');
    localStorage.setItem(AUTH_STORAGE_KEYS.USER_FIRST_NAME, first_name);
    localStorage.setItem(AUTH_STORAGE_KEYS.USER_LAST_NAME, last_name);
    localStorage.setItem(AUTH_STORAGE_KEYS.USER_ID, id.toString());
    localStorage.setItem(AUTH_STORAGE_KEYS.USER_GROUP, JSON.stringify(groups));

    // update user profile does not return following props, they are just part of login response
    if (officeGroupId) {
      localStorage.setItem(AUTH_STORAGE_KEYS.OFFICE_GROUP_ID, officeGroupId.toString());
    }

    if (beta_level)
      localStorage.setItem(AUTH_STORAGE_KEYS.BETA_LEVEL, beta_level.toString());

    if (color_vision_d_15_module)
      localStorage.setItem(AUTH_STORAGE_KEYS.COLOR_VISION_D_15_MODULE, color_vision_d_15_module.toString());

    if (suprafast_module)
      localStorage.setItem(AUTH_STORAGE_KEYS.SUPRAFAST_MODULE, suprafast_module.toString());

    if (demo_mode)
      localStorage.setItem(AUTH_STORAGE_KEYS.DEMO_MODE, demo_mode.toString());

    if (research_mode)
      localStorage.setItem(AUTH_STORAGE_KEYS.RESEARCH_MODE, research_mode.toString());

    if (force_password_change_on_next_login)
      localStorage.setItem(AUTH_STORAGE_KEYS.FORCE_PASSWORD_CHANGE, force_password_change_on_next_login.toString());

    if (doctor_id)
      localStorage.setItem(AUTH_STORAGE_KEYS.DOCTOR_ID, doctor_id.toString());

    if (operator_id)
      localStorage.setItem(AUTH_STORAGE_KEYS.OPERATOR_ID, operator_id.toString());

    if (is_webauthn_authentication_required)
      localStorage.setItem(AUTH_STORAGE_KEYS.IS_WEBAUTHN_AUTHENTICATION_REQUIRED, is_webauthn_authentication_required.toString());

    if (pacs_integration)
      localStorage.setItem(AUTH_STORAGE_KEYS.PACS_INTEGRATION, pacs_integration.toString());

    if (show_baa_agreement)
      localStorage.setItem(AUTH_STORAGE_KEYS.SHOW_BAA_AGREEMENT, show_baa_agreement.toString());

    if (administrated_offices)
      localStorage.setItem(AUTH_STORAGE_KEYS.USER_ADMINISTERED_OFFICES, JSON.stringify(administrated_offices));

    if (enterprise_security)
      localStorage.setItem(AUTH_STORAGE_KEYS.ENTERPRISE_SECURITY, enterprise_security.toString());

    if (enable_offline_mode)
      localStorage.setItem(AUTH_STORAGE_KEYS.OFFLINE_MODE, enable_offline_mode.toString());

    if (enable_zero_pii)
      localStorage.setItem(ENABLE_ZERO_PII_KEY, enable_zero_pii.toString());

    if (enable_sso)
      localStorage.setItem(AUTH_STORAGE_KEYS.ENABLE_SSO, enable_sso.toString());

    if (sso_domain)
      localStorage.setItem(AUTH_STORAGE_KEYS.SSO_DOMAIN, sso_domain.toString());

    if (corporation?.office_groups)
      localStorage.setItem(AUTH_STORAGE_KEYS.OFFICE_GROUPS, JSON.stringify(corporation.office_groups));

    this.trackingService.setUser(authResponse);
  }

  clearLocalData() {
    const ssoDomain = localStorage.getItem(AUTH_STORAGE_KEYS.SSO_DOMAIN);
    localStorage.clear();

    if (ssoDomain)
      localStorage.setItem(AUTH_STORAGE_KEYS.SSO_DOMAIN, ssoDomain);
  }

  updateUserProfile(body: UserUpdateRequest): Observable<User> {
    return this.httpClient.put(API_UPDATE_USER, body).pipe(map((user: User) => {
      this.storeUserData({ user, office_group: {} });
      return user;
    }));
  }

  switchOfficeGroup(officeGroupId: number): Observable<void> {
    return this.httpClient.post(API_SWITCH_OFFICE_GROUP_PATH, { office_group: officeGroupId }).pipe(map(res => res as any));
  }
}
