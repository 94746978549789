// native
import { Subscription, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, debounceTime, tap, mergeMap, retryWhen, delay, take } from 'rxjs/operators';
import { OnInit, EventEmitter, AfterViewInit, OnDestroy, Directive, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// addon
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from "ngx-toastr";

// service
import { TestsService } from 'app/core/services/tests.service';
import { DevicesService } from 'app/core/services/devices.service';
import { AuthService } from 'app/core/services/auth.service';
import { PatientsService } from 'app/core/services/patients.service';
import { MonitorTestService } from 'app/core/services/monitor-test.service';
import { StreamingService } from 'app/core/services/streaming.service';
import { TestBundlesService } from 'app/core/services/test-bundles.service';
import { PreferencesService } from 'app/core/services/preferences.service';
import { DialogService } from 'app/core/services/dialog.service';

// component
import { StreamComponent } from 'app/shared/components/stream/stream.component';

// models
import { Test, TestPutRequest, MonitorEvent, DeviceScreen, Battery, Device, TestBundle, MonitorAction, PerimetryStimulus, SemanticDeviceVersion, Patient, Volume } from '../models';

// constant
import {
  DEVICE_SCREEN, MONITOR_EVENT_TYPE, TEST_ACTION, STRATEGY, DEVICE_ACTION_TIMEOUT,
  CONVERGENCE_PROMPT_TIMEOUT, TEST_STATUS, CONVERGENCE_PROMPT_ID, PROTOCOL, GROUP
} from '../constants';

@Directive()
export abstract class MonitorBaseComponent implements OnInit, AfterViewInit, OnDestroy {
  test: Test;
  isTestDone: boolean;
  isTestSyncing: boolean;

  patient: Patient;

  bundle: TestBundle;

  device: Device;
  deviceVersion: SemanticDeviceVersion;
  isSingleImage: boolean;
  currentDeviceScreen: { id: number, name: string; };
  batteryPercentage: number;

  updatesSubscription: Subscription;

  windowChanged: EventEmitter<any> = new EventEmitter();
  resizeSubscription: Subscription;

  streamPopupVisible: boolean;
  streamStopSubscription?: Subscription;

  isPlotShown: boolean = true;
  isRegionShown: boolean = false;
  isGridShown: boolean = false;
  isGraphOnlyShown: boolean = false;
  isSimpleLayoutShown: boolean = false;

  testLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  deviceLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  deviceActionLoading: boolean = false;

  lastUpdateRecieved = null;
  inactivityPingInterval: any;
  inactivityTimeout: number = null;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public testService: TestsService,
    public testBundlesService: TestBundlesService,
    public toastService: ToastrService,
    public devicesService: DevicesService,
    public authService: AuthService,
    public patientsService: PatientsService,
    public monitorTestService: MonitorTestService,
    public streamingService: StreamingService,
    public preferencesService: PreferencesService,
    public translateService: TranslateService,
    public dialogService: DialogService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.testService.getOne(params['id']).pipe(
        tap(test => {
          this.test = test;
          this.testLoading$.next(false);

          if (this.test.test_bundle?.id)
            this.updateBundleStatus(this.test.test_bundle.id);

          this.onUpdateTestStatus(this.test.status.name);

          this.setUpdatesListener();

          this.patientsService.getOne(this.test.patient.id).subscribe(patient => {
            this.patient = patient;
          });

          this.isPlotShown = ![STRATEGY.SUPRA_FAST, STRATEGY.PTOSIS, STRATEGY.BLEPHARO].includes(this.test.test_group?.strategy?.value);
          this.isRegionShown = [STRATEGY.PTOSIS].includes(this.test.test_group?.strategy?.value);
          this.isGraphOnlyShown = [STRATEGY.FOVEAL_SENSITIVITY].includes(this.test.test_group?.strategy?.value);
          this.isSimpleLayoutShown = [PROTOCOL.SUPRA_THRESHOLD_QUADRANT].includes(this.test.test_group?.protocol?.value);
        }),
        mergeMap(test => {
          return this.devicesService.getOne(test.device);
        })
      ).subscribe(device => {
        this.device = device;
        this.deviceLoading$.next(false);

        this.updateDeviceScreenStatus(device.current_screen);

        this.setInactivityPing();

        this.batteryPercentage = device.battery_level_headset_percentage;

        this.deviceVersion = this.devicesService.getSemanticVersion(device.app_version);
        this.isSingleImage = this.streamingService.isSingleImageStream(device);

        this.getCurrentTestState();

      }, error => {
        this.toastService.error(this.translateService.instant('testNotFound'), this.translateService.instant('error'));
        this.testLoading$.next(false);
        this.deviceLoading$.next(false);
        this.close();
      });
    });

    this.resizeSubscription = this.windowChanged.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(() => this.calculateDimensions());
  }

  ngAfterViewInit() {
    this.windowChanged.emit(this.getEvent());
  }

  ngOnDestroy() {
    this.updatesSubscription?.unsubscribe();
    this.resizeSubscription?.unsubscribe();
    clearInterval(this.inactivityPingInterval);

    if (this.streamPopupVisible)
      this.closeStream();
  }

  @HostListener('window:online', ['$event'])
  onOnline(event: any): void {
    this.setUpdatesListener();
  }

  public close() {
    if (!this.test || this.route.snapshot.queryParams?.from) {
      return this.router.navigate(['/patients']);
    }

    const route = this.test.test_bundle?.id ? '/tests/bundles' : '/tests';
    this.router.navigate([route, this.test.patient.id]);
  }

  public getEvent() {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    };
  }

  public getMarkerColor(record: PerimetryStimulus): number {
    const strategies = [STRATEGY.SUPRA_THRESHOLD, STRATEGY.SUPRA_FAST, STRATEGY.CONFRONTATION,
    STRATEGY.ESTERMAN, STRATEGY.PTOSIS];

    if (strategies.includes(this.test.test_group?.strategy?.value))
      return record.viewed ? 2 : 0;

    if ((<PerimetryStimulus>record).threshold)
      return 2;

    return record.viewed ? 1 : 0;
  }

  protected updateDeviceScreenStatus(deviceScreen: DeviceScreen) {
    if (!deviceScreen?.id) {
      this.currentDeviceScreen = null;
      return;
    }

    this.currentDeviceScreen = deviceScreen;

    if ((deviceScreen.name === DEVICE_SCREEN.CONVERGENCE.value) && !this.test.use_automated_convergence)
      this.showConvergencePrompt();
    else
      this.hideConvergencePrompt();
  }

  public updateBatteryStatus(battery: Battery) {
    this.batteryPercentage = battery.battery_level_headset_percentage;
  }

  public updateVolume(volume: number) {
    if (volume !== null && volume !== undefined)
      this.device.volume = volume;
  }

  public onUpdateTestStatus(status: string) {
    this.isTestDone = (status === TEST_STATUS.FINISHED.value);
    this.isTestSyncing = (status === TEST_STATUS.SYNCING.value);

    if (this.isTestDone) {
      if (this.streamPopupVisible)
        this.closeStream();

      if (this.bundle?.next_test)
        this.monitorTestService.openMonitorScreen(this.bundle.next_test, true);
    }

    if (this.preferencesService.isDemoModeEnabled && this.isTestDone) {
      this.testService.sendReport(this.test.id, this.test.patient.id, true)
        .subscribe(res => {
          this.router.navigate(['demo-mode']);
          this.toastService.info(this.translateService.instant('reportSuccessMessageEmail'));
        }, err => {
          this.router.navigate(['demo-mode']);
          this.toastService.error(this.translateService.instant('reportErrorMessageEmail'));
        });
    }

    if (this.isTestDone || this.isTestSyncing)
      clearInterval(this.inactivityPingInterval);
  }

  private showConvergencePrompt() {
    this.dialogService.openConfirm({
      message: this.translateService.instant('howManyDots'),
      action: this.translateService.instant('select'),
      confirmText: '1',
      cancelText: '2',
      showClose: false,
      id: CONVERGENCE_PROMPT_ID
    }).then(result => {
      if (result.confirmed)
        this.setConvergence(false);
      if (result.canceled)
        this.setConvergence(true);
    });

    setTimeout(() => this.hideConvergencePrompt(), CONVERGENCE_PROMPT_TIMEOUT);
  }

  hideConvergencePrompt() {
    this.dialogService.closeConfirm({ closed: true }, CONVERGENCE_PROMPT_ID);
  }

  setConvergence(isMonocular: boolean) {
    const body: TestPutRequest = {
      monocular: isMonocular
    };
    this.testService.update(this.test.id, body).pipe(
      retryWhen((errors) => errors.pipe(
        delay(4000),
        take(10)
      ))
    ).subscribe(() => { });
  }

  private setUpdatesListener() {
    this.updatesSubscription?.unsubscribe();

    this.updatesSubscription = this.monitorTestService.listenUpdates(this.test.id)
      .subscribe((event: MonitorEvent) => {
        this.handleNewRecordEvent(event);

        if (event.type === MONITOR_EVENT_TYPE.UPDATED_DEVICE_SCREEN)
          this.updateDeviceScreenStatus(<DeviceScreen>event.data);

        if (event.type === MONITOR_EVENT_TYPE.UPDATED_BATTERY)
          this.updateBatteryStatus(<Battery>event.data);

        if (event.type === MONITOR_EVENT_TYPE.UPDATED_DEVICE_VOLUME)
          this.updateVolume((<Volume>event?.data)?.volume);

        if (event.type === MONITOR_EVENT_TYPE.UPDATED_TEST_STATUS)
          this.onUpdateTestStatus(<string>event.data);

        this.lastUpdateRecieved = new Date().getTime();
        this.setInactivityPing();
      },
        error => console.error(error)
      );
  }

  private setInactivityPing() {
    if (!this.inactivityTimeout)
      return;

    if (!this.currentDeviceScreen || (this.currentDeviceScreen?.name !== DEVICE_SCREEN.DOING_TEST.value))
      return;


    clearInterval(this.inactivityPingInterval);

    this.inactivityPingInterval = setInterval(() => {
      const now = new Date().getTime();
      const diff = now - this.lastUpdateRecieved;
      if (!this.lastUpdateRecieved || (diff > this.inactivityTimeout)) {
        this.resetStateAfterReconnection();
        this.getCurrentTestState();
        this.setUpdatesListener();
        this.testService.getOne(this.test.id).subscribe(test => this.onUpdateTestStatus(test.status.name));
      }
    }, this.inactivityTimeout);
  }

  onActionClicked(action: MonitorAction) {
    if (action.value === TEST_ACTION.VOLUME.value)
      return this.triggerAction(action);

    this.dialogService.openConfirm({
      action: null,
      message: this.translateService.instant('areYouSure') + ' ' + this.translateService.instant(action?.translationKey) + ' ?',
      confirmText: (this.test.test_bundle && (action.value === TEST_ACTION.CANCEL.value))
        ? this.translateService.instant('cancelIndividualTest') : this.translateService.instant('yes'),
      altConfirmText: (this.test.test_bundle && (action.value === TEST_ACTION.CANCEL.value))
        ? this.translateService.instant('cancelBundle') : null,
      cancelText: (this.test.test_bundle && (action.value === TEST_ACTION.CANCEL.value))
        ? this.translateService.instant('dontCancel') : this.translateService.instant('no')
    }).then(result => {
      if (result.confirmed)
        this.triggerAction(action);
      if (result.altConfirmed)
        this.cancelBundle();
    });
  }

  triggerAction(action: MonitorAction) {
    switch (action.value) {
      case TEST_ACTION.CANCEL.value:
        this.cancelTest();
        break;

      case TEST_ACTION.PLAY.value:
        this.sendActionToDevice(TEST_ACTION.PLAY.value);
        break;

      case TEST_ACTION.PAUSE.value:
        this.sendActionToDevice(TEST_ACTION.PAUSE.value);
        break;

      case TEST_ACTION.RESTART_TEST.value:
        this.restartTestAction();
        break;

      case TEST_ACTION.FORWARD.value:
        this.sendActionToDevice(TEST_ACTION.FORWARD.value);
        break;

      case TEST_ACTION.BACKWARD.value:
        this.sendActionToDevice(TEST_ACTION.BACKWARD.value);
        break;

      case TEST_ACTION.VOLUME.value:
        this.sendActionToDevice(TEST_ACTION.VOLUME.value, action.payload);
        break;
    }
  }

  updateTestStatus(status: number) {
    const body: TestPutRequest = {
      status
    };
    this.testService.update(this.test.id, body).subscribe(res => { });
  }

  updateBundleStatus(bundleId: number) {
    this.testBundlesService.getOne(bundleId).subscribe(bundle => {
      if (this.test?.status?.name === TEST_STATUS.FINISHED.value)
        this.monitorTestService.openMonitorScreen(bundle.current_test, true);

      this.bundle = bundle;
    });
  }

  sendActionToDevice(message: string, payload?: string) {
    this.deviceActionLoading = message !== TEST_ACTION.VOLUME.value;
    setTimeout(() => { this.deviceActionLoading = false; }, DEVICE_ACTION_TIMEOUT);

    const body: { message: string, payload?: string; } = { message };
    if (payload)
      body.payload = payload;

    this.monitorTestService.sendActionToDevice(this.test?.device, body).subscribe(res => { });
  }

  restartTestAction() {
    this.monitorTestService.restartTest(this.test.id).subscribe(
      test => this.monitorTestService.openMonitorScreen(test, true)
    );
  }

  openStream(streamComponent: StreamComponent) {
    if (this.streamingService.streamPending)
      return this.closeStream();

    if (!this.streamPopupVisible) {
      this.streamingService.streamPending = true;
      this.streamPopupVisible = true;

      this.sendActionToDevice(TEST_ACTION.START_STREAMING.value);

      this.streamingService.getStreamUrl(this.test.device).subscribe(res => {
        if (!res || !res.token_url) {
          this.toastService.error(this.translateService.instant('streamNotShown'));
          this.streamPopupVisible = false;
          this.streamingService.streamPending = false;
          return;
        }

        this.streamingService.streamDevice(
          res.token_url, this.test.device.toString(),
          this.isSingleImage,
          streamComponent.leftImage.nativeElement,
          streamComponent.rightImage.nativeElement);

        this.streamStopSubscription = this.streamingService.streamingStopped$.subscribe(() => {
          this.toastService.error(this.translateService.instant('streamNotShown'));
          this.closeStream();
        });
      }, err => {
        this.toastService.error(this.translateService.instant('streamNotShown'));
        this.streamPopupVisible = false;
        this.streamingService.streamPending = false;
      });
    } else {
      this.closeStream();
    }
  }

  closeStream() {
    this.streamingService.closeStream();
    this.streamPopupVisible = false;
    this.streamStopSubscription?.unsubscribe();
    this.sendActionToDevice(TEST_ACTION.STOP_STREAMING.value);
  }

  cancelTest() {
    this.testService.delete(this.test.id).subscribe(res => {
      if (this.bundle?.next_test)
        this.monitorTestService.openMonitorScreen(this.bundle.next_test, true);
      else
        this.close();
    },
      err => this.toastService.error(this.translateService.instant('cancelTestError')));
  }

  cancelBundle() {
    this.testBundlesService.delete(this.test.test_bundle?.id).subscribe(res => this.close(),
      err => this.toastService.error(this.translateService.instant('cancelTestError')));
  }

  abstract handleNewRecordEvent(event: MonitorEvent): void;

  abstract getCurrentTestState(): void;

  abstract calculateDimensions(): void;

  abstract onToggleGrid(): void;

  abstract resetStateAfterReconnection(): void;
}
