import { TranslatableValue } from "../models";

//Regex for validating password complexity. Must match the constant/regex in the backend that has the same name.
export const USER_PASSWORD_REGEX = /^(?!\s)(?=(.*\d){2})(?=(.*[a-zA-Z]){8,})(?=(.*[*.=!@$%^&(){}:;<>,.?/~_+\-]){2}).{15,}(?<!\s)$/;

export const WHITE_LABEL_SUBDOMAIN = 'forushealth';

export const GROUP = {
  VISUAL_ACUITY: 'VISUAL_ACUITY',
  CONTRAST_SENSITIVITY: 'CONTRAST_SENSITIVITY',
  COLOR_VISION: 'COLOR_VISION',
  EOMS: 'EXTRA_OCULAR_MOTILITY',
  PUPIL: 'PUPIL',
  PERIMETRY: 'PERIMETRY',
  CSUITE: 'CSUITE',
  COVER: 'COVER',
  SENSORIMOTOR_SHAPES: 'SENSORIMOTOR',
  SENSORIMOTOR_CARDINAL: 'SENSORIMOTOR_CARDINAL',
  CUSTOM_BUNDLE: 'CUSTOM_BUNDLE',
  OTHER: 'OTHER',
  ANTERIOR_SEGMENT_IMAGE: 'ANTERIOR_SEGMENT_IMAGE',
  EYE_IMAGE_CAPTURE: 'EYE_IMAGE_CAPTURE',
  ONGOING_EYE_IMAGE_CAPTURE: 'ONGOING_EYE_IMAGE_CAPTURE',
  SENSORIMOTOR_SUITE: 'SENSORIMOTOR_SUITE'
};

export const STRATEGY = {
  PERIMETRY_DEFAULT: 'PERIMETRY_DEFAULT',
  FULL_THRESHOLD: 'FULL_THRESHOLD',
  AVA_STANDARD: 'AVA_STANDARD',
  AVA_FAST: 'AVA_FAST',
  SUPRA_THRESHOLD: 'SUPRA_THRESHOLD',
  SUPRA_FAST: 'SUPRA_FAST',
  PTOSIS: 'PTOSIS',
  ESTERMAN: 'ESTERMAN',
  CONFRONTATION: 'CONFRONTATION',
  KINETIC: 'KINETIC_PERIMETRY',
  FOVEAL_SENSITIVITY: 'FOVEAL_SENSITIVITY',
  BLEPHARO: 'BLEPHARO',
  PUPILLOMETRY: 'PUPILLOMETRY',
  PERRL: 'PERRL',
  BLUE_PUPILLOMETRY: 'BLUE_PUPILLOMETRY',
  D15: 'D15',
  ISHIHARA: 'ISHIHARA',
  WAGGONER: 'WAGGONER',
  WAGGONER_FOR_SENIORS: 'WAGGONER_FOR_SENIORS',
  WAGGONER_FOR_OLDER_CHILDREN: 'WAGGONER_FOR_OLDER_CHILDREN',
  NEAR_VISION: 'NEAR',
  FAR_VISION: 'DISTANCE',
  LOW_CONTRAST: 'LOW_CONTRAST',
  BOTH: 'BOTH',
  EOMS_SENSORIMOTOR: 'EOMS_SENSORIMOTOR',
  COVER_SENSORIMOTOR: 'COVER_SENSORIMOTOR',
  CONTRAST_SINGLE_OPTOTYPE: 'SINGLE_OPTOTYPE',
  CONTRAST_STANDARD: 'CONTRAST_STANDARD',
};

export const PROTOCOL = {
  FULL_THRESHOLD_10_2: '10-2',
  FULL_THRESHOLD_PEDIATRIC_10_2: '10-2-P',
  FULL_THRESHOLD_24_2: '24-2',
  FULL_THRESHOLD_24_2C: '24-2C',
  FULL_THRESHOLD_PEDIATRIC_24_2: '24-2-P',
  FULL_THRESHOLD_30_2: '30-2',

  AVA_FAST_10_2: '10-2-AVA-F',
  AVA_FAST_24_2: '24-2-AVA-F',
  AVA_FAST_30_2: '30-2-AVA-F',
  AVA_FAST_PEDIATRIC_10_2: '10-2-AVA-F-P',
  AVA_FAST_PEDIATRIC_24_2: '24-2-AVA-F-P',
  AVA_FAST_PEDIATRIC_30_2: '30-2-AVA-F-P',

  AVA_STANDARD_24_2: '24-2-AVA-S',
  AVA_STANDARD_30_2: '30-2-AVA-S',
  AVA_STANDARD_PEDIATRIC_24_2: '24-2-AVA-S-P',
  AVA_STANDARD_PEDIATRIC_30_2: '30-2-AVA-S-P',

  SUPRA_THRESHOLD_10_2: '10-2-S',
  SUPRA_THRESHOLD_24_2: '24-2-S',
  SUPRA_THRESHOLD_30_2: '30-2-S',
  SUPRA_THRESHOLD_PEDIATRIC: '24-2-S-P',
  SUPRA_THRESHOLD_QUADRANT: 'QUADRANT',

  LANDOLT_C: 'LANDOLT_C',
  TUMBLING_E: 'TUMBLING_E',

  PTOSIS_24_2_NOVEL: '24-2-PTOSIS-NOVEL',
  PTOSIS_30_2_TRADITIONAL: '30-2-PTOSIS-TRADITIONAL'
};

export const PEDIATRIC_PROTOCOLS = [
  PROTOCOL.FULL_THRESHOLD_PEDIATRIC_10_2,
  PROTOCOL.FULL_THRESHOLD_PEDIATRIC_24_2,
  PROTOCOL.AVA_FAST_PEDIATRIC_10_2,
  PROTOCOL.AVA_FAST_PEDIATRIC_24_2,
  PROTOCOL.AVA_FAST_PEDIATRIC_30_2,
  PROTOCOL.AVA_STANDARD_PEDIATRIC_24_2,
  PROTOCOL.AVA_STANDARD_PEDIATRIC_30_2,
  PROTOCOL.SUPRA_THRESHOLD_PEDIATRIC
];

export const SUPRA_T_INTENSITIES = {
  STANDARD: {
    label: 'standard',
    value: 'STD'
  },
  HIGH: {
    label: 'high',
    value: 'HIGH'
  },
  MAX: {
    label: 'max',
    value: 'MAX'
  }
};

export const DEFAULT_OCCLUSION_TIME = 3;

export const MONITOR_EVENT_TYPE = {
  NEW_PERIMETRY_RECORD: 'new_stimuli',
  NEW_ACUITY_RECORD: 'new_acuity_optotype_result',
  NEW_CONTRAST_RECORD: 'new_contrast_sensitivity_response',
  NEW_EOMS_RECORD: 'new_eoms_response',
  NEW_D15_STATE: 'updated_test_color_vision',
  NEW_ISHIHARA_STATE: 'ishihara_state_update',
  NEW_WAGGONER_STATE: 'waggoner_state_update',
  NEW_PUPILLOMETRY_STATE: 'pupillometry_state_update',
  NEW_COVER_STATE: 'cover_state_update',
  NEW_SENSORIMOTOR_STATE: 'sensorimotor_state_update',
  UPDATED_DEVICE_SCREEN: 'updated_device_screen',
  UPDATED_BATTERY: 'updated_device_battery_level',
  UPDATED_DEVICE_VOLUME: 'new_device_telemetry',
  UPDATED_TEST_STATUS: 'updated_test_status',
  PROGRESS_UPDATE_RECORD: 'test_progress_update'
};

export const DEVICE_SCREEN: { [key: string]: TranslatableValue; } = {
  TUTORIAL: {
    value: 'tutorial',
    translationKey: 'tutorial'
  },
  CONVERGENCE: {
    value: 'testing convergence',
    translationKey: 'convergence'
  },
  PLAY: {
    value: 'play',
    translationKey: 'play'
  },
  DOING_TEST: {
    value: 'doing test',
    translationKey: 'doingTest'
  },
  PAUSED: {
    value: 'test paused',
    translationKey: 'testPaused'
  },
  CALIBRATION: {
    value: 'calibration',
    translationKey: 'calibration'
  },
  WARM_UP: {
    value: 'warm up',
    translationKey: 'warmUp'
  },
  CALIBRATION_ETS: {
    value: 'eye tracking calibration',
    translationKey: 'eyeTrackingCalibration'
  },
  CALIBRATION_V2: {
    value: 'calibration v2',
    translationKey: 'calibrationV2'
  },
  TURORIAL_FOVEAL: {
    value: 'tutorial foveal',
    translationKey: 'fovealTutorial'
  },
  EYE_TRACKING_FOVEAL: {
    value: 'eye tracking foveal',
    translationKey: 'fovealEyeTrackingCalibration'
  },
  DOING_TEST_FOVEAL: {
    value: 'doing test foveal',
    translationKey: 'fovealDoingTest'
  },
  INTRODUCTION: {
    value: 'introduction',
    translationKey: 'introduction'
  }
};

export const CONVERGENCE_PROMPT_TIMEOUT = 40000;
export const CONVERGENCE_PROMPT_ID = 'convergence-prompt-dialog';
export const TAPED_PROMPT_TIMEOUT = 60000;
export const TAPED_PROMPT_ID = 'taped-prompt-dialog';


export const DEVICE_ACTION_TIMEOUT = 2000;
export const DEVICE_LAST_USED_SORT_MIN_LENGTH = 8;

export const EMPLOYEE_GROUPS = [{
  name: 'operator',
  value: 'OPERATOR'
},
{
  name: 'doctor',
  value: 'DOCTOR'
}];

export const USER_GROUP: { [key: string]: TranslatableValue; } = {
  OFFICE_GROUP_ADMIN: {
    value: 'OFFICE_GROUP_ADMIN',
    translationKey: 'officeGroupAdmin'
  },
  TECHALL_ADMIN: {
    value: 'TECHALL_ADMIN',
    translationKey: 'techallAdmin'
  },
  TECHALL_USER: {
    value: 'TECHALL_USER',
    translationKey: 'techallUser'
  },
  OLLEYES_READONLY_SUPPORT: {
    value: 'OLLEYES_READONLY_SUPPORT',
    translationKey: 'olleyesReadonlySupport'
  },
  OLLEYES_BASIC_SUPPORT: {
    value: 'OLLEYES_BASIC_SUPPORT',
    translationKey: 'olleyesBasicSupport'
  },
  OPERATOR: {
    value: 'OPERATOR',
    translationKey: 'operator'
  },
  DOCTOR: {
    value: 'DOCTOR',
    translationKey: 'doctor'
  },
  OFFICE_ADMIN: {
    value: 'OFFICE_ADMIN',
    translationKey: 'officeAdmin'
  },
  CORPORATE_ADMIN: {
    value: 'CORPORATE_ADMIN',
    translationKey: 'corporateAdmin'
  },
};

export const BETA_LEVELS = {
  NONE: 0,
  FIRST: 1,
  SECOND: 2,
  THIRD: 3,
  FOURTH: 4,
  FIFTH: 5,
  SIXTH: 6,
  SEVENTH: 7,
  EIGHTH: 8,
  NINTH: 9,
  TENTH: 10,
  ONE_HUNDRED: 100,
};

export const EYE = {
  OS: 'OS',
  OD: 'OD',
  OU: 'OU',
  BOTH_SERVER: '',
  BOTH_CLIENT: 'both'
};

export const DEFAULT_PAGE_SIZE = 50;
export const DEFAULT_PAGE_INDEX = 0;

export const DEFAULT_SUPPORT_PAGE_SIZE = 50;
export const DEFAULT_SUPPORT_PAGE_INDEX = 0;
export const DEFAULT_SUPPORT_PAGE_SKIP = 0;
export const DEFAULT_SUPPORT_SORT_ORDER = 'asc';

export const DEFAULT_ENCRYPTED_PAGE_SIZE = 20;

export const TEST_ACTION: { [key: string]: TranslatableValue; } = {
  PLAY: {
    value: 'play test',
    translationKey: 'playTest'
  },
  PAUSE: {
    value: 'pause test',
    translationKey: 'pauseTest'
  },
  RESTART_TEST: {
    value: 'restart test',
    translationKey: 'restartTest'
  },
  BACKWARD: {
    value: 'skip backward',
    translationKey: 'skipBackward'
  },
  FORWARD: {
    value: 'skip forward',
    translationKey: 'skipForward'
  },
  START_STREAMING: {
    value: 'start streaming',
    translationKey: 'startStreaming'
  },
  STOP_STREAMING: {
    value: 'stop streaming',
    translationKey: 'stopStreaming'
  },
  CANCEL: {
    value: 'cancel test',
    translationKey: 'cancelTest'
  },
  VOLUME: {
    value: 'volume',
    translationKey: 'volume'
  },
  SKIP_EYE: {
    value: 'acuity_skip_eye',
    translationKey: 'acuitySkipEye'
  }
};

export const TEST_STATUS: { [key: string]: TranslatableValue; } = {
  STARTED: {
    value: 'Started',
    translationKey: 'started'
  },
  PAUSED: {
    value: 'Paused',
    translationKey: 'paused'
  },
  STOPPED: {
    value: 'Stopped',
    translationKey: 'stopped'
  },
  PENDING: {
    value: 'Pending',
    translationKey: 'pending'
  },
  SYNCING: {
    value: 'Syncing',
    translationKey: 'syncing'
  },
  FAILED: {
    value: 'Failed',
    translationKey: 'failed'
  },
  FINISHED: {
    value: 'Finished',
    translationKey: 'finished'
  },
  EXPIRED: {
    value: 'Expired',
    translationKey: 'expired'
  }
};

// todo change api contract to avoid this
export const TEST_STATUS_STARTED_ID = 1;

export const GOLDMAN_SIZES = {
  TWO: {
    value: 2,
    label: 'II'
  },
  THREE: {
    value: 3,
    label: 'III'
  },
  FIVE: {
    value: 5,
    label: 'V'
  }
};

export const TESTING_POSITIONS = {
  PRIMARY_ONLY: {
    label: 'primaryOnly',
    value: 1
  },
  PRIMARY_CARDINAL: {
    label: 'primaryCardinal',
    value: 2
  },
  PRIMARY_EIGHT: {
    label: 'primaryEight',
    value: 3
  }
};

export const DEVICE_TYPE = {
  OFFICE: 'S',
  OFFICE_CHOICE: 'C',
  EYE_TRACKING: 'GT',
  HOME: 'H',
  HOME_EYE_TRACKING: 'ETH',
  CAMERA: 'CAMERA'
};

export const REGION_STEP = {
  ONE: 0,
  TWO: 1,
};

export const AUTH_STORAGE_KEYS = {
  USER_ID: 'user_id',
  USER_EMAIL: 'user_email',
  USER_PHONE_NUMBER: 'phone_number',
  USER_NAME: 'user_name',
  USER_FIRST_NAME: 'user_first_name',
  USER_LAST_NAME: 'user_last_name',
  USER_GROUP: 'user_group',
  USER_ADMINISTERED_OFFICES: 'user_administered_offices',
  BETA_LEVEL: 'beta_level',
  DEMO_MODE: 'demo_mode',
  RESEARCH_MODE: 'research_mode',
  COLOR_VISION_D_15_MODULE: 'color_vision_d_15_module',
  SUPRAFAST_MODULE: 'suprafast_module',
  FORCE_PASSWORD_CHANGE: 'force_password_change_next_login',
  DOCTOR_ID: 'doctor_id',
  OPERATOR_ID: 'operator_id',
  IS_WEBAUTHN_AUTHENTICATION_REQUIRED: 'is_webauthn_authentication_required',
  PACS_INTEGRATION: 'pacs_integration',
  SHOW_BAA_AGREEMENT: 'show_baa_agreement',
  ENTERPRISE_SECURITY: 'enterprise_security',
  OFFLINE_MODE: 'enable_offline_mode',
  ENABLE_SSO: 'enable_sso',
  SSO_DOMAIN: 'sso_domain',
  OFFICE_GROUPS: 'office_groups',
  OFFICE_GROUP_ID: 'office_group_id'
};

export const APP_LANGUAGE_KEY = 'app_language';
export const DASHBOARD_COLLAPSED_KEY = 'dashboard_collapsed';
export const ENABLE_ZERO_PII_KEY = 'enable_zero_pii';
export const WORK_ITEM_FILTER_KEY = 'work_item_filter';

export const EOMS_APPEARANCES = [
  {
    name: 'categories',
    value: 'CAT'
  },
  {
    name: 'degrees',
    value: 'DEG'
  }
];

export const PUPIL_PERIOD_TYPES = {
  ADAPTATION: 'A',
  APD: 'APD',
  STIMULATION: 'S',
  REACT: 'R',
  PHASE_L: 'L',
  PHASE_D: 'D'
};

export const TEST_BUNDLE_TYPES = {
  C_SUITE: 'C-Suite'
};

export const NONE_CORRECTION_TYPE = 'none';

export const OTHER_PROTOCOL = 'OTHER';

export const CONTROL_TYPES = {
  CONTROLLER: "Controller",
  VOICE: "Voice"
};

export const IMPORT_STATUS: { [key: string]: TranslatableValue; } = {
  SUCCEEDED: {
    value: 'SUCCEEDED',
    translationKey: 'succeeded'
  },
  FAILED: {
    value: 'FAILED',
    translationKey: 'needsReview'
  },
  IN_PROGRESS: {
    value: 'IN_PROGRESS',
    translationKey: 'processing'
  },
  NOT_STARTED: {
    value: 'NOT_STARTED',
    translationKey: 'waitingProcessing'
  },
  UPLOAD_FAILED: {
    value: 'UPLOAD_FAILED',
    translationKey: 'uploadFailed'
  },
  UPLOADING: {
    value: 'UPLOADING',
    translationKey: 'uploading'
  },
  QUEUED: {
    value: 'QUEUED',
    translationKey: 'queued'
  }
};

export const PATIENT_JOB_STATUS = {
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
  IN_PROGRESS: 'IN_PROGRESS',
  QUEUED: 'QUEUED',
};

export const APP_LANGUAGES = [
  {
    name: 'English',
    value: 'en-us'
  },
  {
    name: 'Spanish',
    value: 'es-es'
  },
  {
    name: 'French',
    value: 'fr-ca'
  },
  {
    name: 'Portuguese',
    value: 'pt-br'
  },
  {
    name: 'Hebrew',
    value: 'he-il'
  },
  {
    name: 'Italian',
    value: 'it-it'
  },
  {
    name: 'German',
    value: 'de-de'
  },
  {
    name: 'Malay',
    value: 'ms-my'
  },
  {
    name: 'Korean',
    value: 'ko-kr'
  },
  {
    name: 'Arabic',
    value: 'ar-eg'
  },
  {
    name: 'Filipino',
    value: 'fil-ph'
  },
  {
    name: 'Ukranian',
    value: 'uk-ua'
  },
  {
    name: 'Afrikaans',
    value: 'af-za'
  },
  {
    name: 'Russian',
    value: 'ru-ru'
  },
  {
    name: 'Greek',
    value: 'el-gr'
  },
  {
    name: 'Thai',
    value: 'th-th'
  },
  {
    name: 'Vietnamese',
    value: 'vi-vn'
  },
  {
    name: 'Turkish',
    value: 'tr-tr'
  },
  {
    name: 'Japanese',
    value: 'ja-jp'
  },
  {
    name: 'Chinese',
    value: 'zh-cn'
  },
  {
    name: 'Cantonese',
    value: 'zh-yue'
  },
  {
    name: 'Hindi',
    value: 'hi-in'
  },
  {
    name: 'Indonesian',
    value: 'id-id'
  },
  {
    name: 'Croatian',
    value: 'hr-hr'
  },
  {
    name: 'Czech',
    value: 'cs-cz'
  },
  {
    name: 'Polish',
    value: 'pl-pl'
  },
  {
    name: 'Dutch',
    value: 'nl-nl'
  },
  {
    name: 'Norwegian',
    value: 'nb-no'
  },
  {
    name: 'Swedish',
    value: 'sv-se'
  },
  {
    name: 'Danish',
    value: 'da-dk'
  },
  {
    name: 'Hungarian',
    value: 'hu-hu'
  }
];

export const TEST_LANGUAGES =
{
  'english': {
    name: 'english',
    value: 'english'
  },
  'spanish': {
    name: 'spanish',
    value: 'spanish'
  },
  'pl-PL': {
    name: 'polish',
    value: 'pl-PL'
  },
  'fr-CA': {
    name: 'french(Canada)',
    value: 'fr-CA'
  },
  'de-DE': {
    name: 'german',
    value: 'de-DE'
  },
  'ja-JP': {
    name: 'japanese',
    value: 'ja-JP'
  },
  'it-IT': {
    name: 'italian',
    value: 'it-IT'
  },
  'ru-RU': {
    name: 'russian',
    value: 'ru-RU'
  },
  'zh-CN': {
    name: 'chinese',
    value: 'zh-CN'
  },
  'fr-FR': {
    name: 'french(France)',
    value: 'fr-FR'
  },
  'tr-TR': {
    name: 'turkish',
    value: 'tr-TR'
  },
  'ar-EG': {
    name: 'arabic',
    value: 'ar-EG'
  },
  'vi-VN': {
    name: 'vietnamese',
    value: 'vi-VN'
  },
  'he-IL': {
    name: 'hebrew',
    value: 'he-IL'
  },
  'hi-IN': {
    name: 'hindi',
    value: 'hi-IN'
  },
  'ko-KR': {
    name: 'korean',
    value: 'ko-KR'
  },
  'pt-BR': {
    name: 'portuguese',
    value: 'pt-BR'
  },
  'fa-IR': {
    name: 'persian',
    value: 'fa-IR'
  },
  'ta-IN': {
    name: 'tamil',
    value: 'ta-IN'
  },
  'th-TH': {
    name: 'thai',
    value: 'th-TH'
  },
  'el-GR': {
    name: 'greek',
    value: 'el-GR'
  },
  'uk-UA': {
    name: 'ukranian',
    value: 'uk-UA'
  },
  'ms-MY': {
    name: 'malay',
    value: 'ms-MY'
  },
  'id-ID': {
    name: 'indonesian',
    value: 'id-ID'
  },
  'bn-BD': {
    name: 'bengali',
    value: 'bn-BD'
  },
  'fil-PH': {
    name: 'filipino',
    value: 'fil-PH'
  },
  'ne-NP': {
    name: 'nepali',
    value: 'ne-NP'
  },
  'af-ZA': {
    name: 'afrikaans',
    value: 'af-ZA'
  },
  'hr-HR': {
    name: 'croatian',
    value: 'hr-HR'
  },
  'cs-CZ': {
    name: 'czech',
    value: 'cs-CZ'
  },
  'nl-NL': {
    name: 'dutch',
    value: 'nl-NL'
  },
  'nb-NO': {
    name: 'norwegian',
    value: 'nb-NO'
  },
  'sv-SE': {
    name: 'swedish',
    value: 'sv-SE'
  },
  'da-DK': {
    name: 'danish',
    value: 'da-DK'
  },
  'hu-HU': {
    name: 'hungarian',
    value: 'hu-HU'
  },
  'am-ET': {
    name: 'amharic',
    value: 'am-ET'
  },
  'sw-KE': {
    name: 'swahili',
    value: 'sw-KE'
  },
  'zu-ZA': {
    name: 'zulu',
    value: 'zu-ZA'
  },
  'zh-YUE': {
    name: 'cantonese',
    value: 'zh-YUE'
  },
  'lo-LA': {
    name: 'lao',
    value: 'lo-LA'
  }
};

export const REPORT_LANGUAGES = [
  {
    name: 'english',
    value: 'en-us'
  },
  {
    name: 'spanish',
    value: 'es-es'
  }
];

export const REPORT_TYPES = {
  PDF: 'pdf',
  DICOM: 'dicom',
  IMAGE: 'image'
};

export const CREATE_PATIENT_FROM_MWL = 'create-patient-from-mwl';
export const MWL_WORK_ITEM_STATUS = {
  SUCCESS: 'Success',
  WARNING: 'Warning'
};

export const PACS_STATUS: { [key: string]: TranslatableValue; } = {
  INITIALIZED: {
    value: 'INITIALIZED',
    translationKey: 'inProgress'
  },
  MESSAGE_FAILED: {
    value: 'MESSAGE_FAILED',
    translationKey: 'messageFailed'
  },
  MESSAGE_SENT: {
    value: 'MESSAGE_SENT',
    translationKey: 'messageSent'
  },
  AGENT_FAILED: {
    value: 'AGENT_FAILED',
    translationKey: 'agentFailed'
  },
  AGENT_SUCCESS: {
    value: 'AGENT_SUCCESS',
    translationKey: 'agentSuccess'
  }
};

export const INVITATION_STATUS: { [key: string]: TranslatableValue; } = {
  INITIALIZED: {
    value: 'INITIALIZED',
    translationKey: 'inProgress'
  },
  EMAIL_SENT: {
    value: 'EMAIL_SENT',
    translationKey: 'emailSent'
  },
  COMPLETED: {
    value: 'COMPLETED',
    translationKey: 'completed'
  },
  FAILED: {
    value: 'FAILED',
    translationKey: 'failed'
  },
  CANCELED: {
    value: 'CANCELED',
    translationKey: 'canceled'
  },
  EXPIRED: {
    value: 'EXPIRED',
    translationKey: 'expired'
  }
};

export const TEST_TAB_INDEX = {
  TESTS: 0,
  BUNDLES: 1,
  RECURRING_TESTS: 2,
  RECURRING_BUNDLES: 3
};

export const OFFICE_TAB_INDEX = {
  OFFICES: 0,
  USERS: 1,
  INVITATIONS: 2
};

export const MONITOR_INACTIVITY_TIMEOUT = {
  VISUAL_ACUITY: 30,
  COLOR_VISION: 30,
  EOMS: 30,
  PUPIL: 30,
  PERIMETRY: 15,
  COVER: 30,
  SENSORIMOTOR: 30,
  KINETIC: 30
};

export const APD_STIMULATION_TIMES = [3, 4];
export const DEFAULT_APD_STIMULATION_TIME = 3;