<div
  class="monitor-close-button"
  (click)="close()">
</div>

<div #monitorContainer class="monitor_container">
  <app-monitor-dashboard
    [test]="test"
    [testLoading]="testLoading$ | async"
    [patient]="patient"
    [bundle]="bundle"
    [screen]="currentDeviceScreen"
    [isTestDone]="isTestDone"
    [isTestSyncing]="isTestSyncing"
    [deviceLoading]="deviceLoading$ | async"
    [device]="device"
    [batteryPercentage]="batteryPercentage"
    [streamPopupVisible]="streamPopupVisible"
    [isSingleImage]="isSingleImage"
    [deviceActionLoading]="deviceActionLoading"
    (onStreamOpen)="openStream($event)"
    (onStreamClose)="closeStream()"
    (onActionClicked)="onActionClicked($event)">
  </app-monitor-dashboard>

  <div 
    class="monitor_container-column"
    [ngClass]="{'monitor_container-column--single': !isPartOfSensorimotorSuite}"
    (window:resize)="windowChanged.emit(getEvent())">

    <div 
      class="monitor_graph-box"
      [ngClass]="{'monitor_graph-box--single': !isPartOfSensorimotorSuite}">

      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <div class="monitor_eye-box" *ngIf="state?.eye && !isTestDone">
          <div><i class="fas fa-eye fa-4x text--blink"></i></div>
          <br>
          <span>{{ 'currentlyTesting' | translate | titlecase }}: {{ state.eye }}</span> 
          <ng-container *ngIf="!isPartOfSensorimotorSuite" [ngSwitch]="state.phase">
              <div *ngSwitchCase="0">{{ 'phase' | translate | titlecase }}: {{ 'coverUncover' | translate }}</div>
              <div *ngSwitchCase="1">{{ 'phase' | translate | titlecase }}: {{ 'coverAlternate' | translate }}</div>
          </ng-container>
          <div *ngIf="testingPositions">{{ 'testingPositions' | translate | titlecase }}: {{ testingPositions | translate }}</div> 
      </div>
    </div>

    <div [hidden]="!isPartOfSensorimotorSuite" class="monitor_graph-box" #plotContainer>
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>

      <plotly-plot [data]="plotData" *ngIf="layout" [layout]="layout" [config]="monitorTestService.plotOptions"
        [revision]="count" [debug]="true" class="monitor_plot-box">
      </plotly-plot>
    </div>
  </div>

  <div class="monitor_container-column monitor_container-column--single">
    <div class="monitor_graph-box monitor_graph-box--single">
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <app-percentage-circle
        *ngIf="state"
        id="test-percentage"
        class="monitor_circle-box" 
        [percentage]="state.progress">
      </app-percentage-circle>
    </div>
  </div>
</div>