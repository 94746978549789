// native
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

// services
import { AuthService } from './core/services/auth.service';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  constructor(
    protected authService: AuthService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = request.url;
    if (
      this.authService.isCorporateAdmin
      && this.authService.storedOfficeGroupId
      && url.startsWith('/api')
      && !url.includes('auth-token')
      && !url.includes('bootstrap')
    ) {
      request = request.clone({
        setHeaders: {
          'X-Olleyes-Office-Group-Id': this.authService.storedOfficeGroupId.toString(),
        }
      });
    }
    return next.handle(request);
  }
}
