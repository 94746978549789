// native
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-skip-button',
  templateUrl: './skip-button.component.html'
})
export class SkipButtonComponent {
  @Input() visible: boolean;
  @Output() onSkip: EventEmitter<void> = new EventEmitter<void>();

  clicked: boolean = false;

  onClick() {
    this.clicked = !this.clicked;
    this.onSkip.emit();
  }
}
