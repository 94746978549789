<app-center-container
  [title]="title"
  [showClose]="true"
  (close)="close()">

  <br>
  <div *ngIf="user">
    {{ 'selectOfficesForUser' | translate }} {{ user.first_name + ' ' + user.last_name }}:
  </div>
  <br>

  <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit(form)">
    <app-input-wrapper 
      [control]="form.get('offices')" 
      label="{{ 'office' | translate }}">
      <mat-select 
        multiple
        class="form_control"
        aria-label="{{ 'office' | translate }}"
        formControlName="offices"
        disableOptionCentering 
        panelClass="dropdown">
        <mat-option *ngFor="let office of offices" [value]="office.id">
          {{ office.name }}
        </mat-option>
      </mat-select>
    </app-input-wrapper>

    <br>

    <button class="button button--primary form_button" type="submit" [disabled]="!form.valid || !form.dirty">
      <span class="regular">{{ 'done' | translate }}</span>
    </button>
  </form>

</app-center-container>

<app-loader *ngIf="isLoading"></app-loader>