// native
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

// addon
import { TranslateService } from '@ngx-translate/core';

// service
import { ApiService } from 'app/core/services/api.service';
import { UtilityService } from './utility.service';
import { DialogService } from './dialog.service';

// models
import {
  PaginatedItems, TestBundle, TestBundleRequest, TestPostRequest, TestBundleType, TestDescription,
  TestGroupInfo, CustomBundleTestCreationRequest, CustomBundlePostDescription, SemanticDeviceVersion, ControlType, TestBundleListItem, BundleReportMode, ConfirmDialogClosePayload, TestMoveRequest
} from '../../models';

// constants
import {
  API_TEST_BUNDLES_PATH, API_TEST_BUNDLE_TYPES_PATH, API_TEST_BUNDLE_HTML_REPORT_PATH, API_TEST_BUNDLE_PDF_REPORT_PATH, API_TEST_BUNDLE_REPORT_MODE_PATH,
  API_TEST_BUNDLE_DICOM_REPORT_PATH, API_TEST_BUNDLE_IMAGE_REPORT_PATH, DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, TEST_BUNDLE_TYPES, GROUP, PROTOCOL, STRATEGY,
  SUPRA_T_INTENSITIES, GOLDMAN_SIZES, CONTROL_TYPES, DEFAULT_APD_STIMULATION_TIME
} from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class TestBundlesService {

  testBundleTypes: TestBundleType[] = [];

  constructor(
    public apiService: ApiService,
    private utilityService: UtilityService,
    private dialogService: DialogService,
    private translateService: TranslateService
  ) {
    this.getTestBundleTypes().subscribe(types => {
      this.testBundleTypes = types || [];
    });
  }

  create(body: TestBundleRequest | CustomBundleTestCreationRequest): Observable<TestBundle> {
    return this.apiService.post(`${API_TEST_BUNDLES_PATH}`, body).pipe(map(obj => obj as TestBundle));
  }

  getAll(patientId: number, pageSize: number = DEFAULT_PAGE_SIZE, pageIndex: number = DEFAULT_PAGE_INDEX, term: string = null): Observable<PaginatedItems<TestBundleListItem>> {
    let path = `${API_TEST_BUNDLES_PATH}?patient=${patientId}&limit=${pageSize}`;

    if (pageIndex)
      path = path + `&offset=${pageIndex * pageSize}`;

    if (term)
      path = path + `&search=${term}`;

    return this.apiService.get(path) as Observable<PaginatedItems<TestBundleListItem>>;
  }

  getOne(id: number): Observable<TestBundle> {
    return this.apiService.get(`${API_TEST_BUNDLES_PATH}${id}/`).pipe(map(obj => obj as TestBundle));
  }

  delete(id: number): Observable<any> {
    return this.apiService.delete(`${API_TEST_BUNDLES_PATH}${id}/`);
  }

  cancel(id: number): Observable<any> {
    return this.apiService.put(`${API_TEST_BUNDLES_PATH}${id}/cancel/`);
  }

  move(id: number, body: TestMoveRequest): Observable<TestBundle> {
    return this.apiService.post(`${API_TEST_BUNDLES_PATH}${id}/move/`, body).pipe(map(obj => obj as TestBundle));
  }

  getTestBundleTypes(): Observable<TestBundleType[]> {
    return this.apiService.get(`${API_TEST_BUNDLE_TYPES_PATH}`).pipe(map(obj => obj as TestBundleType[]));
  }

  isCSuiteBundle(bundle: TestBundle): boolean {
    const bundleType = bundle?.system_test_bundle_type;
    if (!bundleType)
      return false;
    return (bundleType === this.testBundleTypes.find(type => type.name === TEST_BUNDLE_TYPES.C_SUITE)?.id);
  }

  getBundleReportMode(id: number): Observable<BundleReportMode> {
    return this.apiService.get(`${API_TEST_BUNDLE_REPORT_MODE_PATH}?test_bundle_id=${id}`).pipe(map(obj => obj as BundleReportMode));;
  }

  openHTMLReportWithDialog(id: number, age: number, windowRef: Window = null): void {
    this.dialogService.openConfirm({
      action: '',
      message: this.translateService.instant('chooseOpenReportModePrompt'),
      confirmText: this.translateService.instant('onePageReport'),
      cancelText: this.translateService.instant('multiplePageReport'),
      mediumButtonMode: true,
      showClose: false
    }).then(result => this.openHTMLReport(id, result.confirmed ? 'singlepage' : 'full', age, windowRef));
  }

  openReportDialog(): Promise<ConfirmDialogClosePayload> {
    return this.dialogService.openConfirm({
      action: '',
      message: this.translateService.instant('chooseDownloadReportModePrompt'),
      confirmText: this.translateService.instant('onePageReport'),
      cancelText: this.translateService.instant('multiplePageReport'),
      mediumButtonMode: true,
      showClose: false
    });
  }

  openHTMLReport(id: number, mode: 'full' | 'singlepage', age: number, windowRef: Window = null): void {
    const timezone = this.utilityService.getLocalTimezone();
    const url = `${API_TEST_BUNDLE_HTML_REPORT_PATH}?test_bundle_id=${id}&age=${age}&mode=${mode}&timezone=${timezone}`;
    if (windowRef)
      windowRef.location.href = url;
    else
      window.open(url, '_blank');
  }

  downloadPDFReport(id: number, mode: 'full' | 'singlepage'): Observable<void> {
    const timezone = this.utilityService.getLocalTimezone();
    const url = `${API_TEST_BUNDLE_PDF_REPORT_PATH}?test_bundle_id=${id}&mode=${mode}&timezone=${timezone}`;
    return this.apiService.downloadFile(url);
  }

  downloadDicomReport(id: number, mode: 'full' | 'singlepage'): Observable<void> {
    const timezone = this.utilityService.getLocalTimezone();
    const url = `${API_TEST_BUNDLE_DICOM_REPORT_PATH}?test_bundle_id=${id}&mode=${mode}&timezone=${timezone}`;
    return this.apiService.downloadFile(url);
  }

  downloadImageReport(id: number, mode: 'full' | 'singlepage'): Observable<void> {
    const timezone = this.utilityService.getLocalTimezone();
    const url = `${API_TEST_BUNDLE_IMAGE_REPORT_PATH}?test_bundle_id=${id}&mode=${mode}&timezone=${timezone}`;
    return this.apiService.downloadFile(url);
  }

  private isIshiharaSupported(version: SemanticDeviceVersion): boolean {
    return (version?.major >= 4 && version?.minor >= 4 && version?.patch >= 1)
      || (version?.major >= 4 && version?.minor >= 5)
      || (version?.major >= 5);
  }

  private isIshiharaControllerSelected(cSuiteBody: TestPostRequest | TestDescription, controlTypes: ControlType[]): boolean {
    return (cSuiteBody.control_type === controlTypes.find(type => type.name === CONTROL_TYPES.CONTROLLER)?.id);
  }

  getBundleBodyForCSuite(cSuiteBody: TestPostRequest, version: SemanticDeviceVersion, controlTypes: ControlType[], form?: UntypedFormGroup): TestBundleRequest {
    const isIshiharaSupported = this.isIshiharaSupported(version);

    const monocularConfrontation = form?.value?.monocular_confrontation;
    const monocularColor = form?.value?.monocular_color;

    const bundleBody: TestBundleRequest = {
      patient: cSuiteBody.patient,
      device: cSuiteBody.device,
      doctor: cSuiteBody.doctor,
      operator: cSuiteBody.operator,
      system_test_bundle_type: this.testBundleTypes.find(type => type.name === TEST_BUNDLE_TYPES.C_SUITE)?.id,
      test_descriptions: [
        ...this.getAcuityTestDescriptionsForCSuite(cSuiteBody, controlTypes),
        {
          language: cSuiteBody.language,
          test_group: {
            group: GROUP.EOMS,
            strategy: null,
            protocol: null
          },
          eye: null,
          skip_tutorial: cSuiteBody.skip_tutorial,
          use_short_tutorial: cSuiteBody.use_short_tutorial,
          disable_pause: cSuiteBody.disable_pause,
          subtitles: cSuiteBody.subtitles,
          subtitle_increase_text_size: cSuiteBody.subtitle_increase_text_size,
          skip_calibration: false,
          original_system_test_bundle_type: this.testBundleTypes.find(type => type.name === TEST_BUNDLE_TYPES.C_SUITE)?.id
        },
        {
          language: cSuiteBody.language,
          test_group: {
            group: GROUP.PERIMETRY,
            strategy: STRATEGY.CONFRONTATION,
            protocol: null
          },
          eye: null,
          monocular: monocularConfrontation || false,
          skip_convergence_testing: true,
          supra_t_intensity: SUPRA_T_INTENSITIES.HIGH.value,
          goldman_size: GOLDMAN_SIZES.FIVE.value,
          skip_tutorial: cSuiteBody.skip_tutorial,
          use_short_tutorial: cSuiteBody.use_short_tutorial,
          disable_pause: cSuiteBody.disable_pause,
          subtitles: cSuiteBody.subtitles,
          subtitle_increase_text_size: cSuiteBody.subtitle_increase_text_size,
          control_type: controlTypes.find(type => type.name === CONTROL_TYPES.CONTROLLER)?.id,
          skip_calibration: false,
          original_system_test_bundle_type: this.testBundleTypes.find(type => type.name === TEST_BUNDLE_TYPES.C_SUITE)?.id
        },
        {
          language: cSuiteBody.language,
          test_group: {
            group: GROUP.COLOR_VISION,
            strategy: isIshiharaSupported ? STRATEGY.ISHIHARA : STRATEGY.D15,
            protocol: null
          },
          eye: null,
          monocular: monocularColor || false,
          skip_convergence_testing: true,
          skip_tutorial: cSuiteBody.skip_tutorial,
          use_short_tutorial: cSuiteBody.use_short_tutorial,
          disable_pause: cSuiteBody.disable_pause,
          subtitles: cSuiteBody.subtitles,
          subtitle_increase_text_size: cSuiteBody.subtitle_increase_text_size,
          control_type: isIshiharaSupported ?
            cSuiteBody.control_type || controlTypes.find(type => type.name === CONTROL_TYPES.VOICE)?.id :
            null,
          skip_calibration: isIshiharaSupported
            ? this.isIshiharaControllerSelected(cSuiteBody, controlTypes) ? cSuiteBody.skip_calibration : false
            : cSuiteBody.skip_calibration,
          original_system_test_bundle_type: this.testBundleTypes.find(type => type.name === TEST_BUNDLE_TYPES.C_SUITE)?.id
        },
        {
          language: cSuiteBody.language,
          test_group: {
            group: GROUP.PUPIL,
            strategy: STRATEGY.PERRL,
            protocol: null
          },
          eye: null,
          skip_tutorial: cSuiteBody.skip_tutorial,
          use_short_tutorial: cSuiteBody.use_short_tutorial,
          disable_pause: cSuiteBody.disable_pause,
          subtitles: cSuiteBody.subtitles,
          subtitle_increase_text_size: cSuiteBody.subtitle_increase_text_size,
          apd_stimulation_time: DEFAULT_APD_STIMULATION_TIME,
          skip_calibration: false,
          original_system_test_bundle_type: this.testBundleTypes.find(type => type.name === TEST_BUNDLE_TYPES.C_SUITE)?.id
        }]
    };

    if (cSuiteBody.mwl_query_result_item)
      bundleBody.mwl_query_result_item = cSuiteBody.mwl_query_result_item;

    return bundleBody;
  }

  getCSuiteDescriptions(
    cSuiteDescription: TestDescription,
    version: SemanticDeviceVersion,
    controlTypes: ControlType[],
    monocularConfrontation: boolean,
    monocularColor: boolean
  ): TestDescription[] {
    const isIshiharaSupported = this.isIshiharaSupported(version);

    return [
      ...this.getAcuityTestDescriptionsForCSuite(cSuiteDescription, controlTypes),

      {
        language: cSuiteDescription.language,
        test_group: {
          group: GROUP.EOMS,
          strategy: null,
          protocol: null
        },
        eye: null,
        monocular: null,
        skip_convergence_testing: true,
        skip_tutorial: cSuiteDescription.skip_tutorial,
        use_short_tutorial: cSuiteDescription.use_short_tutorial,
        disable_pause: cSuiteDescription.disable_pause,
        subtitles: cSuiteDescription.subtitles,
        subtitle_increase_text_size: cSuiteDescription.subtitle_increase_text_size,
        skip_calibration: false,
        original_system_test_bundle_type: this.testBundleTypes.find(type => type.name === TEST_BUNDLE_TYPES.C_SUITE)?.id
      },
      {
        language: cSuiteDescription.language,
        test_group: {
          group: GROUP.PERIMETRY,
          strategy: STRATEGY.CONFRONTATION,
          protocol: null
        },
        eye: null,
        monocular: monocularConfrontation || false,
        // convergence is not made inside C-suite, so skip_convergence_testing is hardcoded to true
        skip_convergence_testing: true,
        supra_t_intensity: SUPRA_T_INTENSITIES.HIGH.value,
        goldman_size: GOLDMAN_SIZES.FIVE.value,
        skip_tutorial: cSuiteDescription.skip_tutorial,
        use_short_tutorial: cSuiteDescription.use_short_tutorial,
        disable_pause: cSuiteDescription.disable_pause,
        subtitles: cSuiteDescription.subtitles,
        subtitle_increase_text_size: cSuiteDescription.subtitle_increase_text_size,
        control_type: controlTypes.find(type => type.name === CONTROL_TYPES.CONTROLLER)?.id,
        skip_calibration: false,
        original_system_test_bundle_type: this.testBundleTypes.find(type => type.name === TEST_BUNDLE_TYPES.C_SUITE)?.id
      },
      {
        language: cSuiteDescription.language,
        test_group: {
          group: GROUP.COLOR_VISION,
          strategy: isIshiharaSupported ? STRATEGY.ISHIHARA : STRATEGY.D15,
          protocol: null
        },
        eye: null,
        monocular: monocularColor || false,
        skip_convergence_testing: true,
        skip_tutorial: cSuiteDescription.skip_tutorial,
        use_short_tutorial: cSuiteDescription.use_short_tutorial,
        disable_pause: cSuiteDescription.disable_pause,
        subtitles: cSuiteDescription.subtitles,
        subtitle_increase_text_size: cSuiteDescription.subtitle_increase_text_size,
        control_type: isIshiharaSupported
          ? cSuiteDescription.control_type || controlTypes.find(type => type.name === CONTROL_TYPES.VOICE)?.id
          : null,
        skip_calibration: isIshiharaSupported
          ? this.isIshiharaControllerSelected(cSuiteDescription, controlTypes) ? cSuiteDescription.skip_calibration : false
          : cSuiteDescription.skip_calibration,
        original_system_test_bundle_type: this.testBundleTypes.find(type => type.name === TEST_BUNDLE_TYPES.C_SUITE)?.id
      },
      {
        language: cSuiteDescription.language,
        test_group: {
          group: GROUP.PUPIL,
          strategy: STRATEGY.PERRL,
          protocol: null
        },
        eye: null,
        monocular: null,
        skip_convergence_testing: true,
        skip_tutorial: cSuiteDescription.skip_tutorial,
        use_short_tutorial: cSuiteDescription.use_short_tutorial,
        disable_pause: cSuiteDescription.disable_pause,
        subtitles: cSuiteDescription.subtitles,
        subtitle_increase_text_size: cSuiteDescription.subtitle_increase_text_size,
        apd_stimulation_time: DEFAULT_APD_STIMULATION_TIME,
        skip_calibration: false,
        original_system_test_bundle_type: this.testBundleTypes.find(type => type.name === TEST_BUNDLE_TYPES.C_SUITE)?.id
      }];
  }

  getCSuiteCustomBundleDescriptions(cSuiteDescription: CustomBundlePostDescription, monocularColor: boolean, controlTypes: ControlType[]): CustomBundlePostDescription[] {
    let acuityDescriptions: TestDescription[];

    if (cSuiteDescription.test_group?.strategy !== STRATEGY.BOTH)
      acuityDescriptions = [{
        test_group: {
          group: GROUP.VISUAL_ACUITY,
          strategy: cSuiteDescription?.test_group?.strategy,
          protocol: PROTOCOL.TUMBLING_E
        },
        with_correction: !!cSuiteDescription.correction_type,
        correction_type: cSuiteDescription.correction_type,
        eye: null,
        monocular: null,
        control_type: controlTypes.find(type => type.name === CONTROL_TYPES.CONTROLLER)?.id,
        original_system_test_bundle_type: this.testBundleTypes.find(type => type.name === TEST_BUNDLE_TYPES.C_SUITE)?.id
      }];
    else
      acuityDescriptions = [{
        test_group: {
          group: GROUP.VISUAL_ACUITY,
          strategy: STRATEGY.NEAR_VISION,
          protocol: PROTOCOL.TUMBLING_E
        },
        with_correction: !!cSuiteDescription.correction_type,
        correction_type: cSuiteDescription.correction_type,
        eye: null,
        monocular: null,
        control_type: controlTypes.find(type => type.name === CONTROL_TYPES.CONTROLLER)?.id,
        original_system_test_bundle_type: this.testBundleTypes.find(type => type.name === TEST_BUNDLE_TYPES.C_SUITE)?.id
      }, {
        test_group: {
          group: GROUP.VISUAL_ACUITY,
          strategy: STRATEGY.FAR_VISION,
          protocol: PROTOCOL.TUMBLING_E
        },
        with_correction: !!cSuiteDescription.correction_type,
        correction_type: cSuiteDescription.correction_type,
        eye: null,
        monocular: null,
        control_type: controlTypes.find(type => type.name === CONTROL_TYPES.CONTROLLER)?.id,
        original_system_test_bundle_type: this.testBundleTypes.find(type => type.name === TEST_BUNDLE_TYPES.C_SUITE)?.id
      }];

    // for confrontation test in C-suite that is part of Custom bundle
    // we use hardcoded monocular: false, as this value depends (and may be overriden)
    // of global skip_convergence/monocular setting when test is created from custom bundle

    return [
      ...acuityDescriptions,
      {
        test_group: {
          group: GROUP.EOMS,
          strategy: null,
          protocol: null
        },
        eye: null,
        monocular: null,
        original_system_test_bundle_type: this.testBundleTypes.find(type => type.name === TEST_BUNDLE_TYPES.C_SUITE)?.id
      },
      {
        test_group: {
          group: GROUP.PERIMETRY,
          strategy: STRATEGY.CONFRONTATION,
          protocol: null,
        },
        eye: null,
        monocular: false,
        supra_t_intensity: SUPRA_T_INTENSITIES.HIGH.value,
        goldman_size: GOLDMAN_SIZES.FIVE.value,
        control_type: controlTypes.find(type => type.name === CONTROL_TYPES.CONTROLLER)?.id,
        original_system_test_bundle_type: this.testBundleTypes.find(type => type.name === TEST_BUNDLE_TYPES.C_SUITE)?.id
      },
      {
        test_group: {
          group: GROUP.COLOR_VISION,
          strategy: STRATEGY.D15,
          protocol: null,
        },
        eye: null,
        monocular: monocularColor || false,
        original_system_test_bundle_type: this.testBundleTypes.find(type => type.name === TEST_BUNDLE_TYPES.C_SUITE)?.id
      },
      {
        test_group: {
          group: GROUP.PUPIL,
          strategy: STRATEGY.PERRL,
          protocol: null
        },
        eye: null,
        monocular: null,
        original_system_test_bundle_type: this.testBundleTypes.find(type => type.name === TEST_BUNDLE_TYPES.C_SUITE)?.id
      }];
  }

  private getAcuityTestDescriptionsForCSuite(cSuiteBody: TestPostRequest | TestDescription, controlTypes: ControlType[]): TestDescription[] {
    if (cSuiteBody.test_group?.strategy !== STRATEGY.BOTH)
      return [{
        language: cSuiteBody.language,
        test_group: {
          group: GROUP.VISUAL_ACUITY,
          strategy: cSuiteBody.test_group?.strategy,
          protocol: PROTOCOL.TUMBLING_E
        },
        with_correction: !!cSuiteBody.correction_type,
        correction_type: cSuiteBody.correction_type,
        eye: null,
        monocular: null,
        skip_convergence_testing: true,
        skip_tutorial: cSuiteBody.skip_tutorial,
        use_short_tutorial: cSuiteBody.use_short_tutorial,
        disable_pause: cSuiteBody.disable_pause,
        subtitles: cSuiteBody.subtitles,
        subtitle_increase_text_size: cSuiteBody.subtitle_increase_text_size,
        control_type: controlTypes.find(type => type.name === CONTROL_TYPES.CONTROLLER)?.id,
        skip_calibration: cSuiteBody.skip_calibration,
        original_system_test_bundle_type: this.testBundleTypes.find(type => type.name === TEST_BUNDLE_TYPES.C_SUITE)?.id
      }];
    else
      return [{
        language: cSuiteBody.language,
        test_group: {
          group: GROUP.VISUAL_ACUITY,
          strategy: STRATEGY.NEAR_VISION,
          protocol: PROTOCOL.TUMBLING_E
        },
        with_correction: !!cSuiteBody.correction_type,
        correction_type: cSuiteBody.correction_type,
        eye: null,
        monocular: null,
        skip_convergence_testing: true,
        skip_tutorial: cSuiteBody.skip_tutorial,
        use_short_tutorial: cSuiteBody.use_short_tutorial,
        disable_pause: cSuiteBody.disable_pause,
        subtitles: cSuiteBody.subtitles,
        subtitle_increase_text_size: cSuiteBody.subtitle_increase_text_size,
        control_type: controlTypes.find(type => type.name === CONTROL_TYPES.CONTROLLER)?.id,
        skip_calibration: cSuiteBody.skip_calibration,
        original_system_test_bundle_type: this.testBundleTypes.find(type => type.name === TEST_BUNDLE_TYPES.C_SUITE)?.id
      }, {
        language: cSuiteBody.language,
        test_group: {
          group: GROUP.VISUAL_ACUITY,
          strategy: STRATEGY.FAR_VISION,
          protocol: PROTOCOL.TUMBLING_E
        },
        with_correction: !!cSuiteBody.correction_type,
        correction_type: cSuiteBody.correction_type,
        eye: null,
        monocular: null,
        skip_convergence_testing: true,
        skip_tutorial: cSuiteBody.skip_tutorial,
        use_short_tutorial: cSuiteBody.use_short_tutorial,
        disable_pause: cSuiteBody.disable_pause,
        subtitles: cSuiteBody.subtitles,
        subtitle_increase_text_size: cSuiteBody.subtitle_increase_text_size,
        control_type: controlTypes.find(type => type.name === CONTROL_TYPES.CONTROLLER)?.id,
        skip_calibration: cSuiteBody.skip_calibration,
        original_system_test_bundle_type: this.testBundleTypes.find(type => type.name === TEST_BUNDLE_TYPES.C_SUITE)?.id
      }];
  }

  getGroupInfo(groups: TestGroupInfo[]) {
    let groupNames = [];
    groups?.forEach(group => {
      groupNames.push(group?.group?.name);
    });

    if (groupNames.length > 3)
      return groupNames.slice(0, 3).join(', ') + '...';
    else
      return groupNames.join(', ');
  }
}