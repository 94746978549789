// native
import { NgModule } from "@angular/core";

// module
import { SharedModule } from "../shared/shared.module";

// component
import { EditUserComponent } from "./edit-user/edit-user.component";
import { NewUserComponent } from "./new-user/new-user.component";
import { AssignAdminFormComponent } from "./assign-admin-form/assign-admin-form.component";
import { UserFormComponent } from "./user-form/user-form.component";
import { UsersComponent } from "./users.component";

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    UsersComponent,
    EditUserComponent,
    NewUserComponent,
    UserFormComponent,
    AssignAdminFormComponent
  ]
})
export class UsersModule { }