<app-center-container
  [title]="title"
  [showClose]="true"
  (close)="close()">

  <app-tabs
    *ngIf="tabs.length > 1"
    [tabs]="tabs">
  </app-tabs>

  <br>

  <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit(form)">

    <div [hidden]="activeTab !== 0">

      <app-input-wrapper [control]="form.get('skip_tutorial')">
        <input
          type="checkbox"
          class="checkbox_input"
          formControlName="skip_tutorial"
          (change)="onSkipTutorialChange($event)"
          id="skip_tutorial"
          name="skip_tutorial">
        <label
          for="skip_tutorial"
          class="checkbox_label">
          {{ 'skipTutorial' | translate }}
        </label>
      </app-input-wrapper>

      <app-input-wrapper [control]="form.get('skip_convergence_testing')">
        <input
          type="checkbox"
          class="checkbox_input"
          formControlName="skip_convergence_testing"
          (change)="onSkipConvergenceChange($event)"
          id="skip_convergence_testing"
          name="skip_convergence_testing">
        <label
          for="skip_convergence_testing"
          class="checkbox_label">
          {{ 'skipConvergenceTesting' | translate }}
        </label>
      </app-input-wrapper>

      <app-input-wrapper [control]="form.get('use_automated_convergence')">
        <input
          type="checkbox"
          class="checkbox_input"
          formControlName="use_automated_convergence"
          (change)="onAutomatedConvergenceChange($event)"
          id="use_automated_convergence"
          name="use_automated_convergence">
        <label
          for="use_automated_convergence"
          class="checkbox_label">
          {{ 'useAutomatedConvergence' | translate }}
        </label>
      </app-input-wrapper>

      <app-input-wrapper [control]="form.get('use_headset_alignment')">
        <input
          type="checkbox"
          class="checkbox_input"
          formControlName="use_headset_alignment"
          id="use_headset_alignment"
          name="use_headset_alignment">
        <label 
          for="use_headset_alignment"
          class="checkbox_label">
          {{ 'useHeadsetAlignment' | translate | titlecase }}
        </label>
      </app-input-wrapper>

      <app-input-wrapper [control]="form.get('use_short_tutorial')">
        <input
          type="checkbox"
          class="checkbox_input"
          formControlName="use_short_tutorial"
          (change)="onShortTutorialChange($event)"
          id="use_short_tutorial"
          name="use_short_tutorial">
        <label
          for="use_short_tutorial"
          class="checkbox_label"
          [ngClass]="{ 'checkbox_label--error': form.get('use_short_tutorial').errors && form.get('use_short_tutorial').touched }">
          {{ 'useShortTutorial' | translate }}
        </label>
      </app-input-wrapper>

      <br>

      <app-input-wrapper
        [control]="form.get('device')"
        label="{{ 'device' | translate }}">
        <mat-select
          class="form_control"
          formControlName="device"
          disableOptionCentering
          panelClass="dropdown"
          name="device"
          aria-label="{{ 'device' | translate }}">
          <mat-option [value]="null"></mat-option>
          <mat-option
            *ngFor="let device of devices"
            [value]="device">
            <span>
              {{ device.name }}: {{ !device.available ? ('inUse' | translate) : device.code }}
            </span>
          </mat-option>
        </mat-select>
      </app-input-wrapper>

      <app-input-wrapper
        [control]="form.get('operator')"
        label="{{ 'operatorsName' | translate }}">
        <mat-select
          class="form_control"
          formControlName="operator"
          disableOptionCentering
          panelClass="dropdown"
          name="operator"
          aria-label="{{ 'operatorsName' | translate }}">
          <mat-option value=""></mat-option>
          <mat-option *ngFor="let operator of operators" [value]="operator.id">
            {{ operator.first_name }} {{ operator.last_name }}
          </mat-option>
        </mat-select>
      </app-input-wrapper>

      <app-input-wrapper
        [control]="form.get('group')"
        label="{{ 'testGroup' | translate }}">
        <mat-select
          class="form_control"
          formControlName="group"
          disableOptionCentering
          panelClass="dropdown"
          name="group"
          aria-label="{{ 'testGroup' | translate }}">
          <mat-option value=""></mat-option>
          <mat-option *ngFor="let testGroup of displayedTestGroups" [value]="testGroup.value">
            <i *ngIf="testGroup.value === 'CSUITE'" class="fa fa-layer-group"></i>
            <span>{{ testGroup.name }}</span>
          </mat-option>
          <mat-option *ngIf="displayedCustomBundleGroups?.length" [value]="testFormService.customBundleGroupValue">
            <i class="fa fa-layer-group"></i>
            <span>{{ 'customBundle' | translate | titlecase }}</span>
          </mat-option>
        </mat-select>
      </app-input-wrapper>

      <app-info-box
        *ngIf="testFormService.isGroupVisualHintDisplayed && !selectedTestGroup"
        message="{{ 'resetGroupSelection' | translate }}"
        (onClose)="testFormService.hideGroupVisualHint()">
      </app-info-box>

      <app-input-wrapper
        *ngIf="displayedTestStrategies?.length"
        [control]="form.get('strategy')"
        label="{{ 'testStrategy' | translate }}">
        <mat-select
          class="form_control"
          formControlName="strategy"
          disableOptionCentering
          panelClass="dropdown"
          name="strategy"
          aria-label="{{ 'testStrategy' | translate }}">
          <mat-option value=""></mat-option>
          <mat-option *ngFor="let testStrategy of displayedTestStrategies" [value]="testStrategy.value">
            {{ testStrategy.name }}
          </mat-option>
        </mat-select>
      </app-input-wrapper>

      <app-info-box
        *ngIf="testFormService.isStrategyVisualHintDisplayed && !selectedTestStrategy"
        message="{{ 'resetStrategySelection' | translate }}"
        (onClose)="testFormService.hideStrategyVisualHint()">
      </app-info-box>

      <app-input-wrapper
        *ngIf="displayedTestProtocols?.length > 1"
        [control]="form.get('protocol')"
        label="{{ 'testProtocol' | translate }}">
        <mat-select
          class="form_control"
          formControlName="protocol"
          disableOptionCentering
          panelClass="dropdown"
          name="protocol"
          aria-label="{{ 'testProtocol' | translate }}">
          <mat-option value=""></mat-option>
          <mat-option *ngFor="let testProtocol of displayedTestProtocols" [value]="testProtocol.value">
            {{ testProtocol.name }}
          </mat-option>
        </mat-select>
      </app-input-wrapper>

      <app-input-wrapper
        *ngIf="testFormService.areCustomBundlesDisplayed"
        [control]="form.get('custom_test_bundle_type')"
        label="{{ 'customBundle' | translate }}">
        <mat-select
          class="form_control"
          aria-label="{{ 'customBundle' | translate }}"
          formControlName="custom_test_bundle_type"
          disableOptionCentering
          panelClass="dropdown">
          <mat-option value=""></mat-option>
          <mat-option *ngFor="let customBundle of displayedCustomBundleGroups" [value]="customBundle.custom_test_bundle_info?.custom_test_bundle_id">
            {{ customBundle.name }}
          </mat-option>
        </mat-select>
      </app-input-wrapper>

      <app-input-wrapper
        [control]="form.get('language')"
        label="{{ 'language' | translate }}">
        <mat-select
          class="form_control"
          formControlName="language"
          disableOptionCentering
          panelClass="dropdown"
          name="language"
          aria-label="{{ 'language' | translate }}">
          <mat-option value=""> </mat-option>
          <mat-option *ngFor="let language of displayedTestLanguages" [value]="language.value">
            {{ language.name | translate | titlecase }}
          </mat-option>
        </mat-select>
      </app-input-wrapper>

      <app-info-box
        *ngIf="testFormService.isLanguageVisualHintDisplayed"
        message="{{ 'resetLanguageSelection' | translate }}"
        (onClose)="testFormService.hideLanguageVisualHint()">
      </app-info-box>

      <app-input-wrapper [control]="form.get('always_use_subtitles')">
        <input
          type="checkbox"
          class="checkbox_input"
          formControlName="always_use_subtitles"
          id="always_use_subtitles"
          name="always_use_subtitles">
        <label
          for="always_use_subtitles"
          class="checkbox_label"
          [ngClass]="{ 'checkbox_label--error': form.get('always_use_subtitles').errors && form.get('always_use_subtitles').touched }">
          {{ 'alwaysUseSubtitles' | translate | titlecase }}
        </label>
      </app-input-wrapper>

      <app-input-wrapper [control]="form.get('use_advanced_calibration')">
        <input
          type="checkbox"
          class="checkbox_input"
          formControlName="use_advanced_calibration"
          id="use_advanced_calibration"
          name="use_advanced_calibration">
        <label
          for="use_advanced_calibration"
          class="checkbox_label"
          [ngClass]="{ 'checkbox_label--error': form.get('use_advanced_calibration').errors && form.get('use_advanced_calibration').touched }">
          {{ 'useAdvancedCalibration' | translate | titlecase }}
        </label>
      </app-input-wrapper>

      <app-input-wrapper
        [control]="form.get('system_unit')"
        label="{{ 'systemUnit' | translate }}"
        *ngIf="authService.isOfficeGroupAdmin">
        <mat-select
          class="form_control"
          formControlName="system_unit"
          disableOptionCentering
          panelClass="dropdown"
          name="system_unit"
          aria-label="{{ 'systemUnit' | translate }}">
          <mat-option *ngFor="let systemUnit of preferencesService.SYSTEM_UNITS" [value]="systemUnit.value">
            {{ systemUnit.name | translate | titlecase }}
          </mat-option>
        </mat-select>
      </app-input-wrapper>

      <app-input-wrapper
        [control]="form.get('date_format')"
        label="{{ 'dateFormat' | translate }}"
        *ngIf="authService.isOfficeGroupAdmin">
        <mat-select
          class="form_control"
          formControlName="date_format"
          disableOptionCentering
          panelClass="dropdown"
          name="date_format"
          aria-label="{{ 'dateFormat' | translate }}">
          <mat-option *ngFor="let dateFormat of preferencesService.DATE_FORMATS" [value]="dateFormat.serverValue">
            {{ dateFormat.serverValue + ' (' + dateFormat.exampleDisplay + ')' }}
          </mat-option>
        </mat-select>
      </app-input-wrapper>

      <app-input-wrapper
        [control]="form.get('time_format')"
        label="{{ 'timeFormat' | translate }}"
        *ngIf="authService.isOfficeGroupAdmin">
        <mat-select
          class="form_control"
          formControlName="time_format"
          disableOptionCentering
          panelClass="dropdown"
          name="time_format"
          aria-label="{{ 'timeFormat' | translate }}">
          <mat-option *ngFor="let timeFormat of preferencesService.TIME_FORMATS" [value]="timeFormat.serverValue">
            {{ timeFormat.serverValue + ' (' + timeFormat.exampleDisplay + ')' }}
          </mat-option>
        </mat-select>
      </app-input-wrapper>

      <app-input-wrapper
        [control]="form.get('disable_pause')"
        infoIconText="{{ 'disablePauseInfoMessage' | translate }}"
        [infoIconCheckbox]="true">
        <input
          type="checkbox"
          class="checkbox_input"
          formControlName="disable_pause"
          id="disable_pause"
          name="disable_pause">
        <label
          for="disable_pause"
          class="checkbox_label">
          {{ 'disablePause' | translate }}
        </label>
      </app-input-wrapper>

      <app-input-wrapper [control]="form.get('enable_trigger_haptic_feedback')">
        <input
          type="checkbox"
          class="checkbox_input"
          formControlName="enable_trigger_haptic_feedback"
          id="enable_trigger_haptic_feedback"
          name="enable_trigger_haptic_feedback">
        <label
          for="enable_trigger_haptic_feedback"
          class="checkbox_label">
          {{ 'enableHapticFeedback' | translate | titlecase }}
        </label>
      </app-input-wrapper>

      <app-input-wrapper [control]="form.get('enable_dynamic_matrix')">
        <input
          type="checkbox"
          class="checkbox_input"
          formControlName="enable_dynamic_matrix"
          name="enable_dynamic_matrix"
          id="enable_dynamic_matrix">
        <label
          for="enable_dynamic_matrix"
          class="checkbox_label">
          {{ 'enableDynamicMatrix' | translate | titlecase }}
        </label>
      </app-input-wrapper>

      <app-input-wrapper
        *ngIf="authService.isOfficeGroupAdmin"
        [control]="form.get('enable_notifications_on_home_test_completion')">
        <input
          type="checkbox"
          class="checkbox_input"
          formControlName="enable_notifications_on_home_test_completion"
          (change)="onEnableAccessChange($event)"
          id="enable_notifications_on_home_test_completion"
          name="enable_notifications_on_home_test_completion">
        <label
          for="enable_notifications_on_home_test_completion"
          class="checkbox_label"
          [ngClass]="{ 'checkbox_label--error': form.get('enable_notifications_on_home_test_completion').errors && form.get('enable_notifications_on_home_test_completion').touched }">
          {{ 'enableNotificationsOnHomeTestCompletion' | translate | titlecase }}
        </label>
      </app-input-wrapper>

      <app-input-wrapper [control]="form.get('use_monocular_for_contrast_sensitivity')">
        <input
          type="checkbox"
          class="checkbox_input"
          formControlName="use_monocular_for_contrast_sensitivity"
          id="use_monocular_for_contrast_sensitivity"
          name="use_monocular_for_contrast_sensitivity">
        <label
          for="use_monocular_for_contrast_sensitivity"
          class="checkbox_label"
          [ngClass]="{ 'checkbox_label--error': form.get('use_monocular_for_contrast_sensitivity').errors && form.get('use_monocular_for_contrast_sensitivity').touched }">
          {{ 'useMonocularForContrast' | translate | titlecase }}
        </label>
      </app-input-wrapper>

    </div>

    <div [hidden]="activeTab !== 1">
      <app-input-wrapper
        [control]="form.get('eoms_appearance')"
        label="{{ 'eomsAppearance' | translate }}">
        <mat-select
          class="form_control"
          name="eoms_appearance"
          aria-label="{{ 'eomsAppearance' | translate }}"
          formControlName="eoms_appearance"
          disableOptionCentering
          panelClass="dropdown">
          <mat-option *ngFor="let eomsAppearance of eomsAppearances" [value]="eomsAppearance.value">
            {{ eomsAppearance.name | translate | titlecase }}
          </mat-option>
        </mat-select>
      </app-input-wrapper>

      <div class="form_number-container">
        <label
          for="max_external_low_confidence_sensitivities"
          class="form_number-label">
          {{ 'maxExternalLowConfidenceSensitivities' | translate }}
        </label>
        <app-input-wrapper
          [hidden]="!authService.isResearchModeEnabled"
          [control]="form.get('max_external_low_confidence_sensitivities')"
          identifier="max_external_low_confidence_sensitivities">
          <input
            type="number"
            class="form_number-input"
            formControlName="max_external_low_confidence_sensitivities"
            id="max_external_low_confidence_sensitivities"
            name="max_external_low_confidence_sensitivities"
            min="0"
            max="10">
        </app-input-wrapper>
      </div>

      <app-input-wrapper [control]="form.get('enable_opv_dicom')">
        <input
          type="checkbox"
          class="checkbox_input"
          formControlName="enable_opv_dicom"
          id="enable_opv_dicom"
          name="enable_opv_dicom">
        <label
          for="enable_opv_dicom"
          class="checkbox_label">
          {{ 'enableOpvDicom' | translate }}
        </label>
      </app-input-wrapper>

      <app-input-wrapper [control]="form.get('include_threshold_vf_test_in_single_page_bundle_report')">
        <input
          type="checkbox"
          class="checkbox_input"
          formControlName="include_threshold_vf_test_in_single_page_bundle_report"
          id="include_threshold_vf_test_in_single_page_bundle_report"
          name="include_threshold_vf_test_in_single_page_bundle_report">
        <label
          for="include_threshold_vf_test_in_single_page_bundle_report"
          class="checkbox_label">
          {{ 'includeVfTestSinglePageReport' | translate }}
        </label>
      </app-input-wrapper>

      <app-input-wrapper *ngIf="authService.isOfficeGroupAdmin" [control]="form.get('separate_report_for_each_eye')">
        <input
          type="checkbox"
          class="checkbox_input"
          formControlName="separate_report_for_each_eye"
          id="separate_report_for_each_eye"
          name="separate_report_for_each_eye">
        <label
          for="separate_report_for_each_eye"
          class="checkbox_label">
          {{ 'separateReportForEye' | translate }}
        </label>
      </app-input-wrapper>

      <br>

      <app-input-wrapper
        [control]="form.get('report_language')"
        label="{{ 'language' | translate }}">
        <mat-select
          class="form_control"
          formControlName="report_language"
          disableOptionCentering
          panelClass="dropdown"
          name="report_language"
          aria-label="{{ 'language' | translate }}">
          <mat-option *ngFor="let language of languageService.reportLanguages" [value]="language.value">
            {{ language.name | translate | titlecase }}
          </mat-option>
        </mat-select>
      </app-input-wrapper>

    </div>

    <div [hidden]="activeTab !== 2">
      <app-input-wrapper
        *ngIf="authService.isOfficeGroupAdmin"
        [control]="form.get('user_inactivity_limit_seconds')"
        label="{{ 'inactivityLimits.label' | translate }}"
        [forceMinimized]="true">
        <mat-select
          class="form_control"
          formControlName="user_inactivity_limit_seconds"
          disableOptionCentering
          panelClass="dropdown"
          name="user_inactivity_limit_seconds"
          aria-label="{{ 'inactivityLimits.label' | translate }}">
          <mat-option *ngFor="let limit of preferencesService.USER_INACTIVITY_LIMITS" [value]="limit.value">
            {{ limit.name | translate }}
          </mat-option>
        </mat-select>
      </app-input-wrapper>

      <app-input-wrapper
        *ngIf="authService.isOfficeGroupAdmin || authService.isCorporateAdmin"
        [control]="form.get('force_2fa')">
        <input
          type="checkbox"
          class="checkbox_input"
          formControlName="force_2fa"
          id="force_2fa"
          name="force_2fa">
        <label
          for="force_2fa"
          class="checkbox_label">
          {{ 'force2fa' | translate }}
        </label>
      </app-input-wrapper>

      <app-input-wrapper
        [control]="form.get('enable2fa')"
        infoIconText="{{ 'enable2faOverrideInfoMessage' | translate }}"
        [infoIconCheckbox]="true">
        <input
          type="checkbox"
          class="checkbox_input"
          formControlName="enable2fa"
          id="enable2fa"
          name="enable2fa">
        <label
          for="enable2fa"
          class="checkbox_label">
          {{ 'enable2fa' | translate }}
        </label>
      </app-input-wrapper>

      <app-input-wrapper
        *ngIf="authService.isOfficeGroupAdmin"
        [control]="form.get('enable_webauthn')">
        <input
          type="checkbox"
          class="checkbox_input"
          formControlName="enable_webauthn"
          id="enable_webauthn"
          name="enable_webauthn">
        <label
          for="enable_webauthn"
          class="checkbox_label">
          {{ 'enableWebAuthn' | translate }}
        </label>
      </app-input-wrapper>

      <div
        *ngIf="authService.isOfficeGroupAdmin && form.get('enable_webauthn').value"
        class="mfa_container">
        <div class="mfa_header">
          {{ authenticators?.length ? 'Used authenticators:' : 'No authenticators in use.'}}
          <button
            class="button mfa_button"
            type="button"
            (click)="addAuthenticator()">
            {{ 'addWebAuthnDevice' | translate }}
          </button>
        </div>
        <div
          class="mfa_list"
          [ngClass]="{'mfa_list--empty': authenticators?.length}">
          <div
            *ngFor="let authenticator of authenticators"
            class="mfa_item">
            <div class="mfa_label">{{authenticator.label}} </div>
            <i
              class="mfa_icon far fa-edit"
              (click)="updateAuthenticator(authenticator)">
            </i>
            <i
              class="mfa_icon fas fa-trash"
              (click)="deleteAuthenticator(authenticator)">
            </i>
          </div>
        </div>
      </div>

      <app-input-wrapper
        *ngIf="authService.isOfficeGroupAdmin"
        [control]="form.get('enable_access_to_other_offices')">
        <input
          type="checkbox"
          class="checkbox_input"
          formControlName="enable_access_to_other_offices"
          (change)="onEnableAccessChange($event)"
          id="enable_access_to_other_offices"
          name="enable_access_to_other_offices">
        <label
          for="enable_access_to_other_offices"
          class="checkbox_label"
          [ngClass]="{ 'checkbox_label--error': form.get('enable_access_to_other_offices').errors && form.get('enable_access_to_other_offices').touched }">
          {{ 'enableAccessToOtherOffices' | translate }}
        </label>
      </app-input-wrapper>

      <app-input-wrapper
        *ngIf="authService.isZeroPiiAvailable"
        [control]="form.get('enable_zero_pii')">
        <input
          type="checkbox"
          class="checkbox_input"
          formControlName="enable_zero_pii"
          id="enable_zero_pii"
          name="enable_zero_pii"
          (change)="onZeroPiiChange($event)">
        <label
          for="enable_zero_pii"
          class="checkbox_label">
          {{ 'enableZeroPii' | translate }}
        </label>
      </app-input-wrapper>

    </div>

    <div [hidden]="activeTab !== 3">
      <app-input-wrapper
        [control]="form.get('enable_demo_mode')">
        <input
          type="checkbox"
          class="checkbox_input"
          formControlName="enable_demo_mode"
          (change)="onEnableDemoModeChange($event)"
          id="enable_demo_mode"
          name="enable_demo_mode">
        <label
          for="enable_demo_mode"
          class="checkbox_label">
          {{ 'enableDemoMode' | translate }}
        </label>
      </app-input-wrapper>
    </div>

    <br>

    <button
      class="button button--primary form_button"
      type="submit"
      [disabled]="!form.valid || (!form.dirty && (activeTab !== 2))">
      <span class="regular">{{ 'saveChanges' | translate }}</span>
    </button>

  </form>
</app-center-container>

<app-zero-pii-form
  *ngIf="isZeroPiiFormVisible"
  (onSubmit)="onZeroPiiKeySave($event)"
  (onCancel)="onZeroPiiKeyCancel()">

</app-zero-pii-form>

<app-loader *ngIf="isLoading"></app-loader>
