<div
  class="monitor-close-button"
  (click)="close()">
</div>

<div #monitorContainer class="monitor_container">
  <app-monitor-dashboard
    [test]="test"
    [testLoading]="testLoading$ | async"
    [patient]="patient"
    [bundle]="bundle"
    [screen]="currentDeviceScreen"
    [isTestDone]="isTestDone"
    [isTestSyncing]="isTestSyncing"
    [deviceLoading]="deviceLoading$ | async"
    [device]="device"
    [batteryPercentage]="batteryPercentage"
    [streamPopupVisible]="streamPopupVisible"
    [isSingleImage]="isSingleImage"
    [deviceActionLoading]="deviceActionLoading"
    (onStreamOpen)="openStream($event)"
    (onStreamClose)="closeStream()"
    (onActionClicked)="onActionClicked($event)">
  </app-monitor-dashboard>

  <div class="monitor_container-column">
    <div class="monitor_graph-box">
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <div class="monitor_graph-title">{{ 'right' | translate | titlecase }}</div>
      <div class="monitor_eye-box">
        <span class="text--blink">{{isTestDone ? ('testDone' | translate | titlecase) : (('inProgress' | translate | titlecase) + '...') }}</span>
      </div>
    </div>
  
    <div class="monitor_graph-box">
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <div class="monitor_graph-title">{{ 'left' | translate | titlecase }}</div>
      <div class="monitor_eye-box">
        <span class="text--blink">{{isTestDone ? ('testDone' | translate | titlecase) : (('inProgress' | translate | titlecase) + '...') }}</span>
      </div>
    </div>
  </div>

  <div class="monitor_container-column">
    <div class="monitor_graph-box monitor_graph-box--single">
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <app-percentage-circle
        id="test-percentage"
        class="monitor_circle-box" 
        [percentage]="getPercentage()">
      </app-percentage-circle>
    </div>
  </div>
</div>
