<div
  class="monitor-close-button"
  (click)="close()">
</div>

<div #monitorContainer class="monitor_container">
  <app-monitor-dashboard
    [test]="test"
    [testLoading]="testLoading$ | async"
    [patient]="patient"
    [bundle]="bundle"
    [screen]="currentDeviceScreen"
    [isTestDone]="isTestDone"
    [isTestSyncing]="isTestSyncing"
    [deviceLoading]="deviceLoading$ | async"
    [device]="device"
    [batteryPercentage]="batteryPercentage"
    [streamPopupVisible]="streamPopupVisible"
    [isSingleImage]="isSingleImage"
    [deviceActionLoading]="deviceActionLoading"
    (onStreamOpen)="openStream($event)"
    (onStreamClose)="closeStream()"
    (onActionClicked)="onActionClicked($event)">
  </app-monitor-dashboard>

  <div class="monitor_container-column monitor_container-column--limit-height">
    <ng-container *ngIf="isMonocular === true">
      <div class="monitor_graph-box" [ngClass]="{ 'monitor_graph-box--single' : isSingleOsEye }">
        <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
        <div class="monitor_graph-title">{{ 'left' | translate | titlecase }}</div>
        <br><br>
        <div @startList class="monitor_list-container">
          <div
            *ngFor="let record of osData.slice().reverse(), index as i" 
            @fadeAnimation 
            class="monitor_list-item">
            <ng-container *ngIf="record, else noRecordTemplate">
              <ng-container *ngTemplateOutlet="recordView; context: { record: record }"></ng-container>
            </ng-container>
          </div>
          <small *ngIf="!isLoading && !osData?.length" >{{ 'noTestData' | translate }}</small>
        </div>
      </div>
      <div class="monitor_graph-box">
        <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
        <div class="monitor_graph-title">{{ 'right' | translate | titlecase }}</div>
        <div @startList class="monitor_list-container">
          <div 
            *ngFor="let record of odData.slice().reverse(), index as i"
            @fadeAnimation 
            class="monitor_list-item">
            <ng-container *ngIf="record, else noRecordTemplate">
              <ng-container *ngTemplateOutlet="recordView; context: { record: record }"></ng-container>
            </ng-container>
          </div>
          <small *ngIf="!isLoading && !odData?.length" >{{ 'noTestData' | translate }}</small>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="isMonocular === false">
      <div class="monitor_graph-box monitor_graph-box--single">
        <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
        <div class="monitor_graph-title">{{ 'both' | translate | titlecase }}</div>
        <br><br>
        <div @startList class="monitor_list-container">
          <div
            *ngFor="let record of ouData.slice().reverse(), index as i" 
            @fadeAnimation 
            class="monitor_list-item">
            <ng-container *ngIf="record, else noRecordTemplate">
              <ng-container *ngTemplateOutlet="recordView; context: { record: record }"></ng-container>
            </ng-container>
          </div>
          <small *ngIf="!isLoading && !ouData?.length" >{{ 'noTestData' | translate }}</small>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="monitor_container-column">
    <div class="monitor_graph-box">
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <div *ngIf="question?.length" class="monitor_eye-box">
        <div class="monitor_box-title--small text--uppercase">{{'question' | translate}}&nbsp;&nbsp;</div>
        <div class="monitor_box-title">
          <span *ngFor="let q of question">{{q}}&nbsp;</span>
        </div>
        <br>
        <div class="monitor_box-title--small text--uppercase">{{'answer' | translate}}&nbsp;&nbsp;</div>
        <div class="monitor_box-title">
          <span 
            *ngFor="let v of variants" 
            [ngClass]="{'green': answer.includes(v) && question.includes(v), 'red': answer.includes(v) && !question.includes(v)}">{{v}}
          </span>
        </div>
        <br>
        <div class="monitor_box-title--small">{{ 'currentlyTesting' | translate | titlecase }}: {{ currentEye }}</div> 
      </div>
    </div>

    <div class="monitor_graph-box">
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <app-percentage-circle
        id="test-percentage"
        class="monitor_circle-box" 
        [percentage]="getPercentageDone()">
      </app-percentage-circle>
    </div>
  </div>
</div>

<ng-template #recordView let-record="record">
  {{record.index}}
  <i class="fas fa-eye" [ngClass]="{ 'orange': !record?.correct, 'color-primary': record?.correct }"></i>
  <span 
    matTooltip="{{ 'contrast' | translate | titlecase }}" 
    class="pointer">
    {{ record?.contrast }}
  </span>

  <div style="flex-basis: 20%;">
    <span 
      *ngFor="let a of record.answer.split('')"
      class="red"
      [ngClass]="{'green': record.question.split('').includes(a)}">{{a}}
    </span>
  </div>

  <span>
    {{ record?.correct ? ('seen' | translate) : ('unseen' | translate) }}
  </span>
</ng-template>

<ng-template #noRecordTemplate>
  <span class="text--blink">{{'syncing' | translate | titlecase}}...</span>
</ng-template>

