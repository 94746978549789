// native
import { NgModule } from '@angular/core';

// module
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// addon
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

// component
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { BatteryComponent } from './components/battery/battery.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { ControlsComponent } from './components/controls/controls.component';
import { ItemListComponent } from './components/item-list/item-list.component';
import { VolumeControlComponent } from './components/volume-control/volume-control.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoaderComponent } from './components/loader/loader.component';
import { StreamComponent } from './components/stream/stream.component';
import { PercentageCircleComponent } from './components/percentage-circle/percentage-circle.component';
import { PercentageSquaresComponent } from './components/percentage-squares/percentage-squares.component';
import { LoadComponent } from './components/loader/load.component';
import { ColorCirclesComponent } from './components/color-circles/color-circles.component';
import { CenterContainerComponent } from "./components/center-container/center-container.component";
import { VideoCallComponent } from './components/video-call/video-call.component';
import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component';
import { DirectionArrowComponent } from './components/direction-arrow/direction-arrow.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { InputWrapperComponent } from './components/input-wrapper/input-wrapper.component';
import { PasswordValidatorComponent } from './components/password-validator/password-validator.component';
import { HourglassComponent } from './components/hourglass/hourglass.component';
import { ListHeaderComponent } from './components/list-header/list-header.component';
import { ButtonListComponent } from './components/button-list/button-list.component';
import { ImportListComponent } from './components/import-list/import-list.component';
import { InfoBoxComponent } from './components/info-box/info-box.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { SkipButtonComponent } from './components/skip-button/skip-button.component';

// directive
import { HoverItemDirective } from './directives/hover-item.directive';
import { SwipeItemDirective } from './directives/swipe-item.directive';
import { StopPropagationDirective } from '../shared/directives/stop-propagation.directive';
import { DatePickerFormatDirective } from './directives/date-format.directive';

// pipe
import { BoldThinPipe, TruncatePipe } from '../shared/pipes/pipes';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,

    MatTooltipModule,
    MatSelectModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatPaginatorModule,
    MatAutocompleteModule,

    NgxMatIntlTelInputComponent,
    NgxSliderModule
  ],
  declarations: [
    BreadcrumbComponent,
    BatteryComponent,
    ControlsComponent,
    VolumeControlComponent,
    SkipButtonComponent,
    FooterComponent,
    CenterContainerComponent,
    ItemListComponent,
    LoaderComponent,
    LoadComponent,
    StreamComponent,
    TabsComponent,
    PercentageCircleComponent,
    PercentageSquaresComponent,
    ColorCirclesComponent,
    InputWrapperComponent,
    PasswordValidatorComponent,
    HourglassComponent,
    VideoCallComponent,
    LanguageSwitcherComponent,
    DirectionArrowComponent,
    ListHeaderComponent,
    FileUploaderComponent,
    ButtonListComponent,
    ImportListComponent,
    InfoBoxComponent,
    ConfirmDialogComponent,

    HoverItemDirective,
    SwipeItemDirective,
    StopPropagationDirective,
    DatePickerFormatDirective,

    BoldThinPipe,
    TruncatePipe
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,

    MatTooltipModule,
    MatSelectModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatPaginatorModule,
    MatAutocompleteModule,

    BreadcrumbComponent,
    BatteryComponent,
    ControlsComponent,
    VolumeControlComponent,
    SkipButtonComponent,
    CenterContainerComponent,
    FooterComponent,
    ItemListComponent,
    LoaderComponent,
    LoadComponent,
    StreamComponent,
    TabsComponent,
    PercentageCircleComponent,
    PercentageSquaresComponent,
    ColorCirclesComponent,
    InputWrapperComponent,
    PasswordValidatorComponent,
    HourglassComponent,
    VideoCallComponent,
    LanguageSwitcherComponent,
    DirectionArrowComponent,
    ListHeaderComponent,
    FileUploaderComponent,
    ButtonListComponent,
    ImportListComponent,
    InfoBoxComponent,
    ConfirmDialogComponent,

    HoverItemDirective,
    SwipeItemDirective,
    StopPropagationDirective,
    DatePickerFormatDirective,

    BoldThinPipe,
    TruncatePipe,

    NgxMatIntlTelInputComponent,
    NgxSliderModule
  ],
})
export class SharedModule { }
