<div class="volume-control_container">
  <span class="pointer" (click)="toggleMute()">
    <i class="fa fa-volume-{{isMuted ? 'mute' : 'up'}}"></i>
  </span>

  <ngx-slider
    [(value)]="volume"
    [options]="options"
    (userChangeEnd)="onVolumeChange($event)">
  </ngx-slider>

  <span></span>
</div>