<div
  class="monitor-close-button"
  (click)="close()">
</div>

<div #monitorContainer class="monitor_container">
  <app-monitor-dashboard
    [test]="test"
    [testLoading]="testLoading$ | async"
    [patient]="patient"
    [bundle]="bundle"
    [screen]="currentDeviceScreen"
    [isTestDone]="isTestDone"
    [isTestSyncing]="isTestSyncing"
    [deviceLoading]="deviceLoading$ | async"
    [device]="device"
    [batteryPercentage]="batteryPercentage"
    [streamPopupVisible]="streamPopupVisible"
    [isSingleImage]="isSingleImage"
    [deviceActionLoading]="deviceActionLoading"
    (onStreamOpen)="openStream($event)"
    (onStreamClose)="closeStream()"
    (onActionClicked)="onActionClicked($event)">
  </app-monitor-dashboard>

  <div class="monitor_container-column">
    <div class="monitor_graph-box monitor_graph-box--single" *ngIf="osState" >
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <div class="monitor_graph-title">{{ 'left' | translate | titlecase }}</div>
      <div class="monitor_list-container">
        <div class="monitor_stat-title">
          <span *ngIf="currentOsSection">{{'currentlyTesting' | translate | uppercase}}</span>
          <span *ngIf="osState.result">{{'testScore' | translate | uppercase}}</span>
        </div>
        <div *ngIf="currentOsSection" class="monitor_text-box">
          <strong>{{sectionNames[currentOsSection.section] | titlecase}} {{'section' | translate | titlecase}}</strong>
          <div>{{ 'correctAnswers' | translate }}: {{currentOsSection.correct_answers}} &#47; {{currentOsSection.total_answers}}</div>
        </div>
        <div *ngIf="osState.result" class="monitor_text-box">
          {{ getEyeResultText(osState.result) }}
        </div>
      </div>
    </div>

    <div class="monitor_graph-box monitor_graph-box--single" *ngIf="odState" >
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <div class="monitor_graph-title">{{ 'right' | translate | titlecase }}</div>
      <div class="monitor_list-container">
        <div class="monitor_stat-title">
          <span *ngIf="currentOdSection">{{'currentlyTesting' | translate | uppercase}}</span>
          <span *ngIf="odState.result">{{'testScore' | translate | uppercase}}</span>
        </div>
        <div *ngIf="currentOdSection" class="monitor_text-box">
          <strong>{{sectionNames[currentOdSection.section] | titlecase}} {{'section' | translate | titlecase}}</strong>
          <div>{{ 'correctAnswers' | translate }}: {{currentOdSection.correct_answers}} &#47; {{currentOdSection.total_answers}}</div>
        </div>
        <div *ngIf="odState.result" class="monitor_text-box">
          {{ getEyeResultText(odState.result) }}
        </div>
      </div>
    </div>

    <div class="monitor_graph-box monitor_graph-box--single" *ngIf="bothState" >
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <div class="monitor_list-container">
        <div class="monitor_stat-title">
          <span *ngIf="currentBothSection">{{'currentlyTesting' | translate | uppercase}}</span>
          <span *ngIf="bothState.result">{{'testScore' | translate | uppercase}}</span>
        </div>
        <div *ngIf="currentBothSection" class="monitor_text-box">
          <strong>{{sectionNames[currentBothSection.section] | titlecase}} {{'section' | translate | titlecase}}</strong>
          <div>{{ 'correctAnswers' | translate }}: {{currentBothSection.correct_answers}} &#47; {{currentBothSection.total_answers}}</div>
        </div>
        <div *ngIf="bothState.result" class="monitor_text-box">
          {{ getEyeResultText(bothState.result) }}
        </div>
      </div>
    </div>
  </div>

  <div class="monitor_container-column">
    <div class="monitor_graph-box monitor_graph-box--single" *ngIf="osState">
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <div class="monitor_graph-title">{{ 'left' | translate | titlecase }}</div>
      <app-percentage-circle
        id="test-percentage-left"
        class="monitor_circle-box" 
        [percentage]="osState.percentage_done">
      </app-percentage-circle>
    </div>
    <div class="monitor_graph-box monitor_graph-box--single" *ngIf="odState">
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <div class="monitor_graph-title">{{ 'right' | translate | titlecase }}</div>
      <app-percentage-circle
        id="test-percentage-right"
        class="monitor_circle-box" 
        [percentage]="odState.percentage_done">
      </app-percentage-circle>
    </div>
    <div class="monitor_graph-box monitor_graph-box--single" *ngIf="bothState">
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <app-percentage-circle
        id="test-percentage-both"
        class="monitor_circle-box" 
        [percentage]="bothState.percentage_done">
      </app-percentage-circle>
    </div>
  </div>

  <div class="monitor_container-column">
    <div class="monitor_graph-box monitor_graph-box--single" *ngIf="osState?.sections" >
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <div class="monitor_graph-title">{{ 'left' | translate | titlecase }}</div>
      <div class="monitor_list-container">
        <div class="monitor_stat-title">
          {{'completedSections' | translate | uppercase}}
        </div>
        <br>
        <div 
          *ngFor="let section of osState.sections"
          [hidden]="!section.done"
          class="monitor_list-item monitor_list-item--simple">
          <div [ngClass]="{'text--blink': !section.all_data_received}">
            {{sectionNames[section.section] | titlecase}} 
            <span *ngIf="!section.all_data_received" class="hidden-mobile--sm">&#40;{{'syncing' | translate | titlecase}}&#41;</span>
          </div>
          <div class="{{getSectionClass(section)}}" [ngClass]="{'text--blink': !section.all_data_received}">
            <span class="hidden-mobile--sm">{{section.result?.toLowerCase() | translate | titlecase}}&nbsp;</span>
            <span class="hidden-mobile--sm">&#40;</span>{{section.correct_answers}}&#47;{{section.total_answers}}<span class="hidden-mobile--sm">&#41;</span> 
          </div>
        </div>
      </div>
    </div>

    <div class="monitor_graph-box monitor_graph-box--single" *ngIf="odState?.sections" >
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <div class="monitor_graph-title">{{ 'right' | translate | titlecase }}</div>
      <div class="monitor_list-container">
        <div class="monitor_stat-title">
          {{'completedSections' | translate | uppercase}}
        </div>
        <br>
        <div 
          *ngFor="let section of odState.sections"
          [hidden]="!section.done" 
          class="monitor_list-item monitor_list-item--simple">
          <div [ngClass]="{'text--blink': !section.all_data_received}">
            {{sectionNames[section.section] | titlecase}} 
            <span *ngIf="!section.all_data_received" class="hidden-mobile--sm">&#40;{{'syncing' | translate | titlecase}}&#41;</span>
          </div>
          <div class="{{getSectionClass(section)}}" [ngClass]="{'text--blink': !section.all_data_received}">
            <span class="hidden-mobile--sm">{{section.result?.toLowerCase() | translate | titlecase}}&nbsp;</span>
            <span class="hidden-mobile--sm">&#40;</span>{{section.correct_answers}}&#47;{{section.total_answers}}<span class="hidden-mobile--sm">&#41;</span> 
          </div>
        </div>
      </div>
    </div>

    <div class="monitor_graph-box monitor_graph-box--single" *ngIf="bothState?.sections" >
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <div class="monitor_list-container">
        <div class="monitor_stat-title">
          {{'completedSections' | translate | uppercase}}
        </div>
        <br>
        <div 
          *ngFor="let section of bothState.sections"
          [hidden]="!section.done"
          class="monitor_list-item monitor_list-item--simple">
          <div [ngClass]="{'text--blink': !section.all_data_received}">
            {{sectionNames[section.section] | titlecase}} 
            <span *ngIf="!section.all_data_received" class="hidden-mobile--sm">&#40;{{'syncing' | translate | titlecase}}&#41;</span>
          </div>
          <div class="{{getSectionClass(section)}}" [ngClass]="{'text--blink': !section.all_data_received}">
            <span class="hidden-mobile--sm">{{section.result?.toLowerCase() | translate | titlecase}}&nbsp;</span>
            <span class="hidden-mobile--sm">&#40;</span>{{section.correct_answers}}&#47;{{section.total_answers}}<span class="hidden-mobile--sm">&#41;</span> 
          </div>
        </div>
      </div>
    </div>
  </div>
</div>