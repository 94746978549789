<app-center-container
   [title]="title"
   [showClose]="true"
   (close)="close.emit()">

  <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit(form)">

    <app-input-wrapper
      [control]="form.get('name')"
      label="{{ 'name' | translate }}"
      identifier="name">
      <input
        class="form_control"
        type="text"
        formControlName="name"
        name="name"
        id="name">
    </app-input-wrapper>

    <app-input-wrapper
      [control]="form.get('offices')"
      label="{{ 'office' | translate }}">
      <mat-select
        multiple
        class="form_control"
        formControlName="offices"
        disableOptionCentering
        panelClass="dropdown"
        name="offices"
        aria-label="{{ 'office' | translate }}">
        <mat-option *ngFor="let office of offices" [value]="office.id">
          {{ office.name }}
        </mat-option>
      </mat-select>
    </app-input-wrapper>

    <div *ngIf="controller?.id" class="text--left">
      <br>
      <label>{{ 'code' | translate }}:&nbsp;</label>
      <span>{{ controller.code }}</span>
    </div>

    <button
      class="button button--primary form_button"
      type="submit"
      [disabled]="!form.valid || !form.dirty">
      <span class="regular">{{ 'done' | translate }}</span>
    </button>
  </form>

</app-center-container>

<app-loader *ngIf="isLoading"></app-loader>
