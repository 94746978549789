<div 
  class="header_container" 
  [ngClass]="{'header_container--transparent' : transparentMode}">
  <app-navigation [expanded]="navExpanded">
  </app-navigation>

  <div 
    *ngIf="authService.isCorporateAdmin && selectedOfficeGroupId"
    class="form_field-wrapper form_field-wrapper--small">
    <mat-select 
      class="form_control form_control--small"
      disableOptionCentering 
      panelClass="dropdown"
      name="office_group"
      [(ngModel)]="selectedOfficeGroupId"
      (selectionChange)="submit()"
      aria-label="{{ 'officeGroup' | translate }}">
      <mat-option *ngFor="let group of officeGroups" [value]="group.id">{{ group.name }}</mat-option>
    </mat-select>
  </div>

  <a class="header_link" target="_blank" [routerLink]="['/help']">{{ 'help' | translate }}</a>
  <a class="header_link" *ngIf="!authService.isTechallUser || !techallService.currentSession" [routerLink]="['/contact']">{{ 'contactUs' | translate }}</a>
  <app-language-switcher></app-language-switcher>
</div>

<app-loader *ngIf="isLoading" [transparent]="true"></app-loader>