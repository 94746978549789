// native
import { NgModule } from "@angular/core";

// addon
import * as PlotlyJS from 'plotly.js/dist/plotly.js';
import { PlotlyModule } from 'angular-plotly.js';

PlotlyModule.plotlyjs = PlotlyJS;

// module
import { SharedModule } from "../shared/shared.module";

// component
import { MonitorVisualAcuityComponent } from "./monitor-acuity-test/monitor-acuity-test.component";
import { MonitorPerimetryComponent } from "./monitor-perimetry/monitor-perimetry.component";
import { MonitorEstermanComponent } from "./monitor-esterman/monitor-esterman.component";
import { MonitorColorComponent } from "./monitor-color/monitor-color.component";
import { MonitorEomsComponent } from "./monitor-eoms/monitor-eoms.component";
import { MonitorDashboardComponent } from "./monitor-dashboard/monitor-dashboard.component";
import { MonitorPupillometryComponent } from "./monitor-pupillometry/monitor-pupillometry.component";
import { MonitorIshiharaComponent } from "./monitor-ishihara/monitor-ishihara.component";
import { MonitorCoverComponent } from "./monitor-cover/monitor-cover.component";
import { MonitorSensorimotorComponent } from "./monitor-sensorimotor/monitor-sensorimotor.component";
import { MonitorKineticComponent } from "./monitor-kinetic/monitor-kinetic.component";
import { MonitorCameraComponent } from "./monitor-camera.component/monitor-camera.component";
import { MonitorWaggonerComponent } from './monitor-waggoner/monitor-waggoner.component';
import { MonitorContrastComponent } from './monitor-contrast/monitor-contrast.component';
import { MonitorImageComponent } from './monitor-image/monitor-image.component';


@NgModule({
  imports: [
    SharedModule,
    PlotlyModule
  ],
  declarations: [
    MonitorDashboardComponent,
    MonitorVisualAcuityComponent,
    MonitorPerimetryComponent,
    MonitorEstermanComponent,
    MonitorColorComponent,
    MonitorEomsComponent,
    MonitorPupillometryComponent,
    MonitorIshiharaComponent,
    MonitorCoverComponent,
    MonitorSensorimotorComponent,
    MonitorKineticComponent,
    MonitorCameraComponent,
    MonitorWaggonerComponent,
    MonitorContrastComponent,
    MonitorImageComponent
  ]
})
export class MonitorModule { }